import { Navbar, Nav, NavDropdown} from 'react-bootstrap';
import { Outlet, Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import MessageBadge from '../Components/message_badge';
import HomeIcon from '@mui/icons-material/Home';
import '../Layouts/header.css'
import DocsBadge from '../Components/docs_badge';
import TchrsBadge from '../Components/tchrs_badge';
import SubjectPhotos from '../Components/add_subject_photos';
import LogoutIcon from '@mui/icons-material/Logout';

const AdminHeader = (props) => { 
  return (
    <>
      <Navbar className='position-relative' collapseOnSelect expand='lg' bg='dark' variant='dark'>
          
          <Navbar.Toggle className='ms-3' aria-controls='responsive-navbar-nav'/>
             
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className="nav mt-4 mt-lg-2 mb-4 mb-lg-2 ms-4  align-items-start align-items-lg-center">
                <Avatar src={props.avatar} />
                <Link className='nav-item' to="/"><HomeIcon/></Link>
                <NavDropdown title="דוחות" id="navbarScrollingDropdown">
                  <NavDropdown.Item><Link className='nav-drop' to="/TchsIncomeReport">העברות בנקאיות למורים</Link></NavDropdown.Item>                  
                  {/*<NavDropdown.Item><Link className='nav-drop' to="/SchsIncomeReport">העברות בנקאיות למרכזי לימוד</Link></NavDropdown.Item>*/} 
                  <NavDropdown.Divider />
                  <NavDropdown.Item><Link className='nav-drop' to="/TchsTaxReport">תשלומי מס הכנסה למורים</Link></NavDropdown.Item>                                                     
                </NavDropdown>                                
                <Link className='nav-item me-2' to="/ConfirmDocs" ><DocsBadge  count={props.docsNum}/></Link>
                <Link className='nav-item me-2' to="/ConfirmTchrs"><TchrsBadge count={props.tchsNum}/></Link>
                <SubjectPhotos/>
                <Link className='nav-item' to="/AdminProfile">פרופיל</Link>                               
                <Link className='nav-item' to="/Messages"><MessageBadge count={props.messagesCount}/></Link>                         
                <a className='nav-item' onClick={() => props.disconnect()}><LogoutIcon/><b> יציאה</b></a>
          </Nav>
        </Navbar.Collapse>

        <div className='tch_brand-pos'>          
          <Navbar.Brand href="/" className="text-light">
            <b>DoroosOnline</b>
          </Navbar.Brand>
        </div>
        
      </Navbar>

      <Outlet />

    </>
  )
};

export default AdminHeader;