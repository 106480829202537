import React, { useState, useEffect } from "react";
import PageTitle from "../Components/page_title";
import "./About.css"; // Ensure to create a corresponding CSS file

const PrivacyPage = (props) => {
  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    setScreenHeight(window.innerHeight - 200);
  }, []);

  return (
    <div className="container x_container" style={{ minHeight: `${screenHeight}px` }}>
      <div>
        <div className="mt-4">
          <PageTitle text="מדיניות פרטיות" />
        </div>

        <div className="about-container">
          <p><b>1. מבוא</b><br />
            אנו ב-DoroosOnline מתייחסים ברצינות לפרטיות המשתמשים שלנו. מדיניות פרטיות זו מסבירה כיצד אנו אוספים, משתמשים ושומרים על המידע האישי שלך.
          </p>
          <p><b>2. איסוף מידע</b><br />
            אנו אוספים מידע אישי שאתה מספק לנו בעת הרשמה, כמו שם, כתובת דוא"ל ומספר טלפון. בנוסף, אנו אוספים מידע על הפעילות שלך באתר, כגון קורסים שנרשמת אליהם ושיעורים שהשתתפת בהם.
          </p>
          <p><b>3. שימוש במידע</b><br />
            המידע שאנו אוספים משמש למטרות הבאות:<br />
            - לספק את השירותים שלנו ולשפר את חוויית המשתמש.<br />
            - לשלוח הודעות על עדכונים, שיעורים וקורסים.<br />
            - לנתח נתונים לשיפור הפלטפורמה שלנו.
          </p>
          <p><b>4. שיתוף מידע</b><br />
            אנו לא נשתף את המידע האישי שלך עם צדדים שלישיים, למעט אם הדבר נדרש לצורך מתן השירותים שלנו, כמו עיבוד תשלומים, או כאשר הדבר נדרש על פי חוק.
          </p>
          <p><b>5. הגנה על מידע</b><br />
            אנו מיישמים אמצעי אבטחה מתקדמים כדי להגן על המידע האישי שלך מפני גישה לא מורשית, שינוי, חשיפה או השמדה.
          </p>
          <p><b>6. שימוש בעוגיות (Cookies)</b><br />
            אנו משתמשים בעוגיות כדי לשפר את חוויית המשתמש באתר. באפשרותך לשנות את הגדרות הדפדפן שלך כדי לחסום עוגיות, אך הדבר עשוי לפגוע בפונקציונליות של האתר.
          </p>
          <p><b>7. אבטחת תשלומים</b><br />
            אנו עובדים עם ספקי שירותי תשלום מאובטחים כדי לעבד את התשלומים שלך בצורה בטוחה ומוגנת. כל המידע הרגיש, כגון פרטי כרטיס אשראי, עובר הצפנה באמצעות פרוטוקול SSL ואינו נשמר בשרתי DoroosOnline. תשלומים מתבצעים באמצעות ספקי צד שלישי, וכדאי לעיין במדיניות הפרטיות שלהם לצורך מידע נוסף על עיבוד הנתונים.
          </p>
          <p><b>8. גישה ועדכון מידע</b><br />
            המשתמשים יכולים לגשת למידע האישי שלהם ולעדכן אותו דרך פרופיל המשתמש שלהם. אם ברצונך למחוק את חשבונך, פנה אלינו.
          </p>
          <p><b>9. שינויים במדיניות</b><br />
            אנו שומרים לעצמנו את הזכות לעדכן מדיניות זו מעת לעת. הודעה על שינויים תישלח למשתמשים באמצעות הדוא"ל או תפורסם באתר.
          </p>
          <p><b>10. יצירת קשר</b><br />
            אם יש לך שאלות בנוגע למדיניות פרטיות זו, תוכל ליצור איתנו קשר בכתובת support@doroosonline.com.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;
