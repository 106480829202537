
import React,{useState,useEffect} from "react";
import { Route, Routes,BrowserRouter} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';

import Footer         from "./Layouts/footer";
import Login          from "./Pages/login";
import TchLogin       from "./Pages/tch_login";
import AdminLogin from "./Pages/admin_login";
import About          from "./Pages/about";
import Messages       from "./Pages/messages";
import Contact        from "./Pages/contact";
import AdminHeader    from "./Layouts/admin_header";
import StdHeader      from "./Layouts/std_header";
import TchHeader      from "./Layouts/tch_header";
import SchHeader      from "./Layouts/sch_header ";
import Header         from "./Layouts/header";
import StdCourses     from "./Pages/std_courses";
import SchTchCourses from "./Pages/sch_tch_courses";
import StdClassOnline from "./Pages/std_classonline";
import TchClassOnline from "./Pages/tch_classonline";
import StdProfile     from "./Pages/std_profile";
import TchProfile     from "./Pages/tch_profile";
import SchProfile     from "./Pages/sch_profile";
import AdminProfile from "./Pages/admin_profile";
import ShoppingCart   from "./Pages/shopping_cart";
import SearchCourse   from "./Pages/search_course";
import SchTchClassRoom from "./Pages/sch_tch_classroom";
import StdClassRoom   from "./Pages/std_classroom";
import MeetingRequests from "./Pages/confirm_meetings";
import MeetingsCoordination from "./Pages/meetings_coordination";
import DoneLessons from "./Pages/tch_done_lessons";
import SchInvoices from "./Pages/sch_invoice";
import TchsIncomeReport from "./Pages/tchs_income_report";
import SummaryReportByTch from "./Pages/summary_report_by_tch";
import axios from 'axios';
import ConfirmDocuments from "./Pages/confirm_docs";
import ConfirmTeachers from "./Pages/confirm_teachers";
import Home from "./Pages/home";
import ReactLoading from 'react-loading';
import { logout,updateExpirationTime } from "./Components/db";
import { v4 as uuidv4 } from 'uuid';
import LoginPage from "./Pages/login_page";
import AccessibilityMenu from "./Components/accessibility_menu";
import TchsTaxReport from "./Pages/monthly_tchs-tax_report_";
import TermsPage from "./Pages/terms_page";
import PrivacyPage from "./Pages/privacy_page";


const baseUrl = process.env.REACT_APP_BASE_URL;

const App = () => {
  
  const [user,setUser]=useState("DEFAULT");
  const [flag,setFlag]=useState(false);
  const [amount,setAmount]=useState(0);
  const [docsNum,setDocsNum]=useState(0);
  const [tchsNum,setTchsNum]=useState(0);
  const [requestsNum,setRequestsNum]=useState(0);
  const [messagesCount,setMessagesCount]=useState(0);
  const [enabled,setEnabled]=useState(false);
  const [blocked,setBlocked]=useState(false);
  const [isDuplicateTab, setIsDuplicateTab] = useState(false);
  const [fire,setFire]=useState(false);
    
  const [loginState,setLoginState]=useState({
    isLoggedIn:false,
    userID:'',
    id:'',
    name:'',
    email:'',
    picture:'',
    auth:'',
  }); 
//-------------------------------------------------------------------------------------------

  const refreshName = (name) => {
    setLoginState((prevState) => ({
      ...prevState,
      name: name, 
    }))
  }
//-------------------------------------------------------------------------------------------

  const refreshPicture = (photo) => {
    setLoginState((prevState) => ({
      ...prevState,
      picture: photo, 
    }))
  }
//-------------------------------------------------------------------------------------------

  const resetLoginData = () => {
    setLoginState({
      isLoggedIn:false,
      enabled:false,
      blocked:false,
      userID:'',
      id:'',
      name:'',
      email:'',
      picture:'',
      auth:'',
    });
    setUser("DEFAULT"); 
    setBlocked(false);
    setEnabled(false);
  }
//-------------------------------------------------------------------------------------------

  const disconnect= async () => {     
    try {
      
      setFire(true)
      await logout(loginState.id)

      resetLoginData();
      window.localStorage.removeItem('loginData');

    } catch(error) {

      alert(error.message)
    } finally { 

      setFire(false)
    }  
  }
//-------------------------------------------------------------------------------------------

  const getItemWithLastActivity = (key) => {
    
    const itemStr = window.localStorage.getItem(key)
    if (!itemStr) {
      return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()

    if (now.getTime()-item.lastActivity >= process.env.REACT_APP_TTL ) {
      return null
    }

    return {
      data: item.data,
      startActivity: item.startActivity,
      lastActivity: item.lastActivity,
    };
    
  }
//-------------------------------------------------------------------------------------------

  const getLoginDtlsFromSessions=()=>{

    const loginData =getItemWithLastActivity('loginData')
     
    if (loginData) {
      
      setLoginState({
        isLoggedIn: loginData.data.isLoggedIn,      
        userID    : loginData.data.userID,
        id    : loginData.data.id,
        name      : loginData.data.name,
        email     : loginData.data.email,
        picture   : loginData.data.picture,
        auth    : loginData.data.auth,
      });
      
      setUser(loginData.data.user)

      if(loginData.data.enabled == 1)
        setEnabled(true)
      else
        setEnabled(false)

      if(loginData.data.blocked == 1)
        setBlocked(true)
      else
        setBlocked(false)

    } else {
      resetLoginData();
      window.localStorage.removeItem('loginData');
    }
           
  }  
//-------------------------------------------------------------------------------------------

  const connect=(e)=>{ 
      getLoginDtlsFromSessions();           
  }
//-------------------------------------------------------------------------------------------

  const fetchCartAmount = async () =>{

    try {
          const response = await axios.get(`${baseUrl}/get_user_cart_items_amount.php`,{
            params: {                        
              userId:loginState.userID                                                          
            }
          })
      
          if( response.data>= 0 ){         
            setAmount(response.data)        
          }
          else{
            setAmount(0)
          }        
        } 
        catch (error) {
          alert(error) ;      
        } 
  }
//-------------------------------------------------------------------------------------------

  const fetchRequestsCount = async () =>{

    try {
          const response = await axios.get(`${baseUrl}/get_meeting_requests_count.php`,{
            params: {                        
              tchId:loginState.userID                                                          
            }
          })
      
          if( response.data>= 0 ){         
            setRequestsNum(response.data)        
          }
          else{
            setRequestsNum(0)
          }        
        } 
        catch (error) {
          alert(error) ;      
        } 
  }
//-------------------------------------------------------------------------------------------

  const fetchMessagesCount = async () =>{

    try {
          const response = await axios.get(`${baseUrl}/get_messages_count.php`)
         
          if( response.data>= 0 ){         
            setMessagesCount(response.data)        
          }
          else{
            setMessagesCount(0)
          }        
        } 
        catch (error) {
          alert(error) ;      
        } 
  }
//-------------------------------------------------------------------------------------------

  const fetchDocsNumber = async () =>{

    try {          
          if(user==="ADMIN")
            var response = await axios.get(`${baseUrl}/get_docs_count.php`)
          else
            var response = await axios.get(`${baseUrl}/get_docs_count_for_sch.php`,{
              params: {
                  userId:loginState.userID,                                                                      
              }
            })
           
          if( response.data>= 0 ){         
            setDocsNum(response.data)        
          }
          else{
            setDocsNum(0)
          }        
        } 
        catch (error) {
          alert(error) ;      
        } 
  }
//-------------------------------------------------------------------------------------------

const fetchTchrsNumber = async () => {
  try {

      let response;
      if (user === "ADMIN") {
          response = await axios.get(`${baseUrl}/get_tchs_count.php`);
      } else {
          response = await axios.get(`${baseUrl}/get_tchs_count_for_sch.php`, {
              params: {
                  userId: loginState.userID,
              }
          });
      }

      if (response.data.status === 'success') {
          setTchsNum(response.data.tchsNum);
      } else if (response.data.status === 'error') {
          throw new Error(response.data.message || 'An unknown error occurred');
      }

  } catch (error) {
      alert('Error: ' + error.message);
      setTchsNum(0); 
  }
};

//-------------------------------------------------------------------------------------------

  useEffect(()=>{
    
    if(user==="STD")
      fetchCartAmount();

    if(user==="ADMIN" || user==="SCH"){
      fetchDocsNumber();
      fetchTchrsNumber();
    }

    if(user==="ADMIN")
      fetchMessagesCount();
    
    if(user==="TCH")
     fetchRequestsCount();

     if(user===null)
      setUser("DEFAULT")
                    
  },[user])
//-------------------------------------------------------------------------------------------
  
  useEffect(()=>{

    getLoginDtlsFromSessions(); 

  },[]);
//-------------------------------------------------------------------------------------------
    
useEffect(() => {

  const updateLastActivity = () => {
      const itemStr = window.localStorage.getItem('loginData');
      if (itemStr) {
          const item = JSON.parse(itemStr);
          item.lastActivity = Date.now();
          window.localStorage.setItem('loginData', JSON.stringify(item));
      }
  };

  const setupListeners = () => {
      window.addEventListener('click', updateLastActivity);
      window.addEventListener('keydown', updateLastActivity);
  };

  const removeListeners = () => {
      window.removeEventListener('click', updateLastActivity);
      window.removeEventListener('keydown', updateLastActivity);
  };

  if (loginState.isLoggedIn) {
      setupListeners();
  } else {
      removeListeners();
  }

  return () => {
      removeListeners();
  };

}, [loginState.isLoggedIn]); 

//-------------------------------------------------------------------------------------------

  useEffect(() => {
    const generateToken = () => uuidv4();

    const sessionToken = generateToken();
    localStorage.setItem('sessionToken', sessionToken);

    const handleStorageChange = (event) => {
        if (event.key === 'sessionToken') {
          setIsDuplicateTab(true);
        }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
        window.removeEventListener('storage', handleStorageChange);
        localStorage.removeItem('sessionToken');
    };
  }, []);
//-------------------------------------------------------------------------------------------

  useEffect(() => {
      
    const checkLoginDataExpiration = async () => {

      try{
        const loginData = getItemWithLastActivity('loginData');
      
        if (!loginData) {
          disconnect();
        }
        else {
          await updateExpirationTime(loginState.id,loginData.lastActivity-loginData.startActivity)                    
        } 
      } catch (error) {
        alert(error.message)
      }  
    };

    let intervalId;
    if(loginState.isLoggedIn) {
      intervalId = setInterval(checkLoginDataExpiration,process.env.REACT_APP_TTL);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };

  }, [disconnect, loginState.isLoggedIn]);
//-------------------------------------------------------------------------------------------

  const login=()=>{
    setFlag((f)=>!f);
  }
//-------------------------------------------------------------------------------------------

  const increaseAmount = ()=>{
    setAmount(prev => prev+1)
  }
//-------------------------------------------------------------------------------------------

  const decreaseAmount = (num)=>{
    setAmount(prev => prev-num)
  }
//-------------------------------------------------------------------------------------------

  const decreaseDocsNum = ()=>{
    setDocsNum(prev => prev-1)
  }
//-------------------------------------------------------------------------------------------

  const decreaseTchsNum = ()=>{
    setTchsNum(prev => prev-1)
  }
//-------------------------------------------------------------------------------------------

  const decreaseRequestsNum = ()=>{
    setRequestsNum(prev => prev-1)
  }
//-------------------------------------------------------------------------------------------

  const decreaseMessagesCount = ()=>{
    setMessagesCount(prev => prev-1)

  }
//-------------------------------------------------------------------------------------------

  return ( 
    <>
      {fire?
        <div className="position-fixed top-50 start-50 translate-middle" style={{zIndex: 1050}}> {/* Note: zIndex may need adjustment */}
          <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
        </div>
        :
        null
      }

      <AccessibilityMenu/>

      { isDuplicateTab?
        <div className="d-flex justify-content-center align-items-center vh-100" dir="ltr">
          <div className="text-center p-5 bg-light border rounded-3 shadow-lg">
            <p className="display-6">DoroosOnline is open in another window !!!</p>
          </div>
        </div>
        :<>     
          { !loginState.isLoggedIn&&(window.location.pathname==="/admin_login")?
            <>
              <BrowserRouter>
                <Routes>                                                                                                                                                                        
                    <Route 
                      path="admin_login"    
                      element={<AdminLogin connect={connect}/>}
                    />                                                              
                </Routes>
              </BrowserRouter>
            </>
          :
            <>
              <BrowserRouter>
                <Routes> 
                  <Route path="/" element={
                    user==="STD"  &&loginState.isLoggedIn?
                      <StdHeader    
                        amount={amount}                     
                        disconnect={disconnect} 
                        avatar={loginState.picture}
                      />:
                    user==="TCH"  &&loginState.isLoggedIn?
                      <TchHeader    
                        requestsNum={requestsNum}           
                        disconnect={disconnect} 
                        avatar={loginState.picture}
                      />:
                    user==="SCH"  &&loginState.isLoggedIn?
                      <SchHeader    
                        docsNum={docsNum} 
                        tchsNum={tchsNum} 
                        disconnect={disconnect} 
                        avatar={loginState.picture}
                      />:
                    user==="ADMIN"&&loginState.isLoggedIn?
                      <AdminHeader  
                        docsNum={docsNum} 
                        tchsNum={tchsNum} 
                        messagesCount={messagesCount}                   
                        disconnect={disconnect} 
                        avatar={loginState.picture}
                      />:
                    <Header login={login}/>
                  }>
                  
                    <Route path="/" element={<Home user={user} userId={loginState.userID} />} />
                    <Route path="terms" element={<TermsPage/>} />
                    <Route path="privacy_policy" element={<PrivacyPage/>} />
                    
                    <Route path="stdcourses"     element={user==="STD"?<StdCourses user={user} userId={loginState.userID}/>:<Home user={user} userId={loginState.userID} />}/>
                    
                    <Route path="stdclassonline" element={
                      user==="STD"?
                        <StdClassOnline 
                          user={user} 
                          userId={loginState.userID} 
                          increaseAmount={increaseAmount}
                        />:
                        <Home user={user} userId={loginState.userID} />
                      }
                    />

                    <Route path="stdclassroom"   element={
                      user==="STD"?
                        <StdClassRoom 
                          user={user} 
                          userId={loginState.userID} 
                          increaseAmount={increaseAmount}
                        />:
                        <Home user={user} userId={loginState.userID}/>
                      }
                    />
                    
                    <Route path="stdprofile"     element={user==="STD"?
                        <StdProfile 
                          userId={loginState.userID} 
                          id={loginState.id} 
                          auth={loginState.auth} 
                          refreshName={refreshName}
                          refreshPicture={refreshPicture}
                        />
                        :
                        <Home 
                          user={user} 
                          userId={loginState.userID}
                        />
                      }
                    />

                    <Route path="cart"           element={user==="STD"?<ShoppingCart user={user} userId={loginState.userID} id={loginState.id} name={loginState.name} email={loginState.email} decreaseAmount={decreaseAmount}/>:<Home user={user} userId={loginState.userID}/>}/>
                    <Route path="search"         element={user==="STD" || user==="DEFAULT"?<SearchCourse user={user} userId={loginState.userID}/>  :<Home user={user} userId={loginState.userID}/>}/>
                    <Route path="privatemeetings" element={user==="STD"?<MeetingsCoordination user={user} userId={loginState.userID} id={loginState.id} name={loginState.name} email={loginState.email}/>:<Home user={user} userId={loginState.userID}/>}/>
                                
                    <Route path="tchclassonline" element={
                      user==="TCH"?
                        <TchClassOnline 
                          user={user} 
                          userId={loginState.userID}                    
                        />:
                        <Home user={user} userId={loginState.userID}/>
                      }
                    />

                    <Route path="tchclassroom"   element={
                      user==="TCH" || user==="SCH"?
                        <SchTchClassRoom 
                          user={user} 
                          userId={loginState.userID} 
                          enabled={enabled & !blocked}                    
                        />:
                        <Home user={user} userId={loginState.userID}/>
                      }
                    />

                    <Route path="tchprofile"     element={user==="TCH"?
                        <TchProfile 
                          userId={loginState.userID} 
                          user={user} id={loginState.id} 
                          auth={loginState.auth} 
                          refreshName={refreshName}
                          refreshPicture={refreshPicture}
                        />
                        :
                        <Home 
                          user={user} 
                          userId={loginState.userID}
                        />
                      }
                    /> 

                    <Route path="doneLessons"    element={user==="TCH"?<DoneLessons userId={loginState.userID} />:<Home user={user} userId={loginState.userID}/>}/>
                    <Route path="meetingsreq"    element={user==="TCH"?<MeetingRequests decreaseRequestsNum={decreaseRequestsNum} userId={loginState.userID}/>:<Home user={user} userId={loginState.userID}/>}/>

                    <Route path="schreport"      element={user==="SCH"?<SchInvoices userId={loginState.userID} user={user} />:<Home user={user} userId={loginState.userID}/>}/>
                    <Route path="courses"        element={user==="TCH" || user==="SCH"?<SchTchCourses  userId={loginState.userID} user={user} enabled={enabled & !blocked} />:<Home user={user} userId={loginState.userID}/>}/>

                    <Route path="schprofile"     element={user==="SCH"?
                        <SchProfile  
                          userId={loginState.userID} 
                          user={user} id={loginState.id} 
                          auth={loginState.auth} 
                          refreshName={refreshName}
                          refreshPicture={refreshPicture}
                        />
                        :
                        <Home 
                          user={user} 
                          userId={loginState.userID}
                        />
                      }
                    />

                    <Route path="AdminProfile"   element={user==="ADMIN"?
                        <AdminProfile 
                          id={loginState.id} 
                          refreshName={refreshName}
                          refreshPicture={refreshPicture}
                        />
                        :
                        <Home 
                          user={user} 
                          userId={loginState.userID}
                        />
                      }
                    />
                    
                    <Route path="ConfirmDocs"    element={user==="ADMIN" || user==="SCH"?<ConfirmDocuments decreaseDocsNum={decreaseDocsNum} user={user} id={loginState.id}/>:<Home user={user} userId={loginState.userID}/>}/>    
                    <Route path="ConfirmTchrs"   element={user==="ADMIN" || user==="SCH"?<ConfirmTeachers  decreaseTchsNum={decreaseTchsNum} user={user} id={loginState.id}/>:<Home user={user} userId={loginState.userID}/>}/>
                    <Route path="TchsIncomeReport"   element={user==="ADMIN"?<TchsIncomeReport/>:<Home user={user} userId={loginState.userID}/>}/> 
                    <Route path="TchsTaxReport"   element={user==="ADMIN"?<TchsTaxReport/>:<Home user={user} userId={loginState.userID}/>}/> 
                    <Route path="SummayReportByTch"   element={user==="ADMIN"?<SummaryReportByTch/>:<Home user={user} userId={loginState.userID}/>}/>                                                                             
                    <Route path="messages"       element={user==="ADMIN"?<Messages decreaseMessagesCount={decreaseMessagesCount}/>:<Home user={user} userId={loginState.userID}/>}/>
                    
                    <Route path="contact"        element={<Contact/>}/>
                    <Route path="about"          element={<About/>}/>                           
                    <Route path="*"              element={<Home user={user} userId={loginState.userID}/>}/>
                    <Route 
                      path="login"          
                      element={<Login connect={connect}/>}
                    />   
                    <Route 
                      path="tch_login"      
                      element={<TchLogin connect={connect}/>}
                    />   
                    <Route 
                      path="login_page"      
                      element={<LoginPage/>}
                    />                                                                                  
                  </Route>
                </Routes>
              </BrowserRouter>
              <Footer/>
            </> }          
        </> } 
    </> 
  );
};

export default App;
