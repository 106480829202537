import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Button from "@mui/material/Button";
import "../Components/forms.css";
import "./popup_modals.css";
import { FaChalkboardTeacher } from "react-icons/fa";
import * as Yup from "yup";
import { updateLessonSubject } from "./db";
import ReactLoading from "react-loading";

// Validation schema
const subjectSchema = Yup.object().shape({
  subject: Yup.string()
    .min(5, 'Subject must be at least 5 characters')
    .max(40, 'Subject must be at most 40 characters')
    .required('Subject is required'),
});


const UpdateLessonSubject = (props) => {
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);
  const [fire, setFire] = useState(false);
  const [subject, setSubject] = useState("");

  // Handle opening the modal
  useEffect(() => {
    if (!props.link) setShow(true);
  }, []);

//-------------------------------------------------------------------------------------------

  const handleChange = (event) => {
    setSubject(event.target.value);
  };
//-------------------------------------------------------------------------------------------

  const alert = (msg, status) => {
    closeSubjectBox();
    props.alert(msg, status);
  };
//-------------------------------------------------------------------------------------------

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFire(true);

    try {
        
        await subjectSchema.validate({ subject }, { abortEarly: false });
        const response = await updateLessonSubject(subject, props.lessonId, props.mode);
        props.updateLessonSubject(subject, props.index);
        alert(response.message, "SUCCESS");
      
    } catch (error) {
      if (error.inner) {
        const formattedErrors = error.inner.reduce((acc, { path, message }) => {
          acc[path] = message;
          return acc;
        }, {});
        setErrors(formattedErrors);
      } else {
        alert(error.message, "ERROR");
      }
    } finally {
      setFire(false);
    }
  };
//-------------------------------------------------------------------------------------------

  const closeSubjectBox = () => {
    setShow(false);
    setSubject("");
    setErrors({});
    if (!props.link) props.resetMenu(0);
  };
//-------------------------------------------------------------------------------------------

  const openSubjectBox = () => {
    setShow(true);
  };
//-------------------------------------------------------------------------------------------

  return (
    <div>
      {props.link ? (
        <Button
          variant="contained"
          color="success"
          size="small"
          onClick={openSubjectBox}
        >
          עדכון נושא שיעור
        </Button>
      ) : null}

      <Modal show={show} size="md" aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header
          style={{
            backgroundColor: "#f5f5f5",
            borderBottom: "1px solid #dee2e6",
            padding: "10px 15px",
            height: "60px",
          }}
        >
          <div className="d-flex gap-2 align-items-center">
            <div>
              <FaChalkboardTeacher size={30} />
            </div>
            <div className="mt-1">
              <h5>עדכון נושא שיעור</h5>
            </div>
          </div>
          <button
            type="button"
            className="custom-modal-close"
            aria-label="Close"
            onClick={closeSubjectBox}
          >
            &times; {/* Close "X" icon */}
          </button>
        </Modal.Header>

        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="container">
              {fire ? (
                <div
                  className="position-absolute top-50 start-50 translate-middle-x"
                  style={{ zIndex: "1", top: "300", left: "300" }}
                >
                  <ReactLoading
                    type="spin"
                    color="rgb(53, 126, 221)"
                    height={70}
                    width={70}
                  />
                </div>
              ) : null}
              <div className="label-100-wrap mt-4">
                <label className="form-label label-100" htmlFor="subject">
                  נושא השיעור
                </label>
                <div>
                  <textarea
                    className={`form-control ${errors.subject ? "invalid" : ""}`}
                    id="subject"
                    name="subject"
                    rows="3"
                    value={subject}
                    onChange={handleChange}
                    placeholder="נושא שיעור"
                  ></textarea>
                  {errors.subject && <p className="error">{errors.subject}</p>}
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="d-flex gap-3 me-3 mb-2">
              <Button variant="contained" color="error" size="small" onClick={closeSubjectBox}>
                ביטול
              </Button>
              <Button type="submit" variant="contained" color="success" size="small">
                שמירה
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default UpdateLessonSubject;
