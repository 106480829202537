import React, { useEffect } from "react";
import { login, signup } from "./db";
import { SiFacebook } from "react-icons/si";
import './social_btns.css';

const FacebookBtn = (props) => {
  useEffect(() => {
    // Define fbAsyncInit before the script runs
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_CLIENT_ID, // Replace with your Facebook App ID
        cookie: true,
        xfbml: true,
        version : 'v5.0' // Use a valid version number
      });
    };
  }, []);

  // Save data in localStorage with expiration logic
  const setItemWithExpiration = (key, data) => {
    const now = new Date();
    const currentTime = now.getTime();

    const item = {
      data: data,
      startActivity: currentTime,
      lastActivity: currentTime,
    };

    window.localStorage.setItem(key, JSON.stringify(item));
  };

  // Handle Facebook Login
  const handleFacebookLogin = () => {
    window.FB.login(function (response) {
      if (response.status === 'connected') {
        props.turnFireOn();

        // Fetch additional user data using the Facebook Graph API
        window.FB.api('/me', { fields: 'name,email,picture' }, function (userInfo) {
          const facebookId = response.authResponse.userID;
          const name = userInfo.name;
          const email = userInfo.email;
          const imageUrl = userInfo.picture.data.url;
          const auth = "FACEBOOK";
          const user = props.user;

          const [f_name, ...l_nameArray] = name.split(" ");
          const l_name = l_nameArray.join(" ");

          const handleAuth = async () => {
            try {
              let authResponse;
              if (props.mode === 'login') {
                authResponse = await login(user, auth, email, facebookId);
              } else {
                authResponse = await signup(user, auth, email, facebookId, f_name, l_name);
              }

              const [id, userId, full_name, photo, enabled, blocked] = authResponse;

              if (id > 0 && userId > 0) {
                const loginData = {
                  isLoggedIn: true,
                  userID: userId,
                  id: id,
                  name: full_name,
                  email: email,
                  picture: photo !== null ? photo : imageUrl,
                  auth: auth,
                  enabled: enabled,
                  blocked: blocked,
                  user: user,
                };

                setItemWithExpiration('loginData', loginData);
                props.connect();
              } else {
                throw new Error("Login failed. Please try again.");
              }
            } catch (error) {
              props.alert(error.message, 'ERROR');
            } finally {
              props.turnFireOff();
            }
          };

          // Call the async function
          handleAuth();
        });
      } else {
        props.alert("Facebook login failed", 'ERROR');
      }
    }, { scope: 'public_profile,email' });
  };

  return (
    <button
      className="fb-login-button social-btn"
      onClick={handleFacebookLogin}
      disabled={props.disabled}
    >
      <div className="d-flex align-items-center gap-3 ps-2">
        <span>
          <SiFacebook className="icon" />
        </span>
        <span>התחבר באמצעות פייסבוק</span>
      </div>
    </button>
  );
};

export default FacebookBtn;
