import React, { useState } from "react";
import { storage } from "./firebase";
import { ref,uploadBytes,getDownloadURL} from "firebase/storage";
import { v4 } from 'uuid';
import avatar1 from "../Assets/Images/img_avatar1.png" 
import '../Components/upload_photo.scss'


const UploadPhotoFile = (props) => {

    const [selectedFile,setSelectedFile]=useState(null);
    const [url,setUrl]=useState('')

    const changeHandler = (event) => {
        const myFile=event.target.files[0]
        const fileDtls=myFile.name.split(".")
        
        if(fileDtls.length>1){
            const format = fileDtls[fileDtls.length - 1].toLowerCase();
            if(format==='bmp'||format==='jpg'||format==='jpeg'||format==='gif'||format==='png'){
                setSelectedFile(myFile)
                setUrl(URL.createObjectURL(myFile))
            }
            else{
                alert("סוג הקובץ אינו מתאים!!")
                document.getElementById('file').value= null; 
                setSelectedFile(null)
            }
        }
        else{
            alert("שם קובץ אינו תקין!!")
            document.getElementById('file').value= null; 
            setSelectedFile(null)
        }                    
    }
//-------------------------------------------------------------------------------------------      
    
    const imageFileUpload = async () => {
        
        if (!selectedFile) {
            alert("No file selected.");
            return;
        }

        try {
            props.isLoding(true);

            const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
            const uniqueFileName = `${v4()}.${fileExtension}`;
            const fileRef = ref(storage, `pics/${uniqueFileName}`);

            await uploadBytes(fileRef, selectedFile);

            const url = await getDownloadURL(fileRef);
            
            const id = await props.addPhoto(url);

            if (id < 0) {
                throw new Error("Failed to add the new photo.");
            }

        } catch (error) {
            props.alert(error.message || "An error occurred while uploading the file.",'ERROR');
        } finally {
            document.getElementById('file').value = '';
            setSelectedFile(null);
            props.isLoding(false);
            setUrl('')
        }
    }
//-------------------------------------------------------------------------------------------      

    return ( 
                            
        <div className="d-flex flex-column align-items-center gap-5">

            <div className="icon-container">
                <div className="frame">
                    <img 
                        src={url===''?avatar1:url}
                        alt="subject icon" 
                        className="icon" 
                    />
                </div>
            </div> 

            <div>
                <input 
                    type="file" 
                    className="form-control" 
                    name="file" id="file" 
                    onChange={changeHandler} 
                    hidden
                    disabled={props.count>=4}
                />
                <label className="label-file" for="file" style={{cursor:"pointer"}}>בחר תמונה</label>
            </div>

            <div>
                {selectedFile!=null?            
                    <button class="btn btn-warning btn-sm" style={{borderRadius:"5px",width:"130px"}} onClick={imageFileUpload}>העלאת תמונה</button>
                    :
                    <button class="btn btn-warning btn-sm" style={{borderRadius:"5px",width:"130px"}} disabled>העלאת תמונה</button>
                }
            </div> 

        </div>                                                                                                                         
    )

}

export default UploadPhotoFile;
