import React, { useState } from "react";
import Divider from './divider'
import './bill.css'

const ReportSummary=(props)=>{ 
     
    return ( 
        <div className="price-box p-3 pb-3 pb-lg-5">
            
            <div className="pb-0 pb-lg-2">
                <h5><b>סיכום תשלומים</b></h5>
            </div>

            <Divider color="rgb(0,0,0,0.3)" height="2px"/>

            <div class="mt-3 mx-3 d-flex justify-content-between price-info">
                <span>סה"כ תשלומים</span>
                <span>${props.total}</span>
            </div>
            
        </div> 
       
    )  
} 
  
export default ReportSummary;  
