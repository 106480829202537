import { useState,useEffect } from "react";

const SelectUser = (props) => {
    const [user,setUser]=useState({
        tch:true,//tch:false,
        sch:false
    }); 
    
//-------------------------------------------------------------------------------------------
           
    return(
                    
        <div class="d-flex flex-wrap align-items-center gap-4">
                            
            <div class="form-check" > 
                <input 
                    class="form-check-input" 
                    type="radio" 
                    name="user"  
                    id="sch" 
                    checked={user.sch} 
                    onClick={() =>{                   
                        setUser({['tch']:false,['sch']:true});  
                        props.changeUser('SCH')
                        props.isChecked(true);            
                    }}
                    disabled={true}
                >   
                </input>
                <label class="form-check-label" for="sch">מרכז לימוד</label>                                
            </div>

            <div class="form-check" > 
                <input 
                    class="form-check-input" 
                    type="radio" 
                    name="user"  
                    id="tch" 
                    checked={user.tch} 
                    onClick={() =>{                    
                        setUser({['tch']:true,['sch']:false});
                        props.changeUser('TCH')
                        props.isChecked(true);                
                    }}
                    disabled={true}
                >
                </input>
                <label class="form-check-label" for="tch">מורה</label>                                
            </div> 
                                                
        </div>                                   
    )};
  
export default SelectUser;
