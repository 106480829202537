import React, { useState, useEffect } from "react";
import PageTitle from "../Components/page_title";
import "./About.css"; // Ensure to create a corresponding CSS file

const About = (props) => {
  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    setScreenHeight(window.innerHeight - 200);
  }, []);

  return (

    <div className="container x_container" style={{ minHeight: `${screenHeight}px`}}>

    
        <div>
          <div className="mt-4">
            <PageTitle text="אודות"/>
          </div>

          <div className="about-container">

            <p>
              <h5><b>ברוכים הבאים ל-DoroosOnline,</b></h5> 
              הבית הווירטואלי לחינוך חדשני ברחבי האינטרנט. הפלטפורמה שלנו מציעה פתרון מקיף ללמידה מקוונת במגוון תחומי דעת, מותאמת לצרכים המשתנים של המורים והתלמידים בעידן הדיגיטלי.
            </p>

            <p>
            <h5><b>מי אנחנו?</b></h5>
              DoroosOnline הוקמה מתוך מטרה להפוך את הלמידה לנגישה יותר לכל אחד ואחת, בכל מקום ובכל זמן. אנו שמים דגש על שיתוף פעולה בין מורים לתלמידים ומאפשרים למורים ליצור קורסים מקוונים בקלות ולשתף אותם עם קהל רחב של תלמידים.
            </p>

            <p>
            <h5><b>השירותים שלנו</b></h5>
              באמצעות מערכת רישום פשוטה ויעילה, מורים מכל העולם יכולים לפתח קורסים איכותיים בנושאים שבהם הם מתמחים. הפלטפורמה מאפשרת למורים להוסיף מפגשים חדשים לקורסים ומודיעה לתלמידים על כך באמצעות הודעות דוא"ל ו-SMS. תלמידים יכולים לרכוש גישה לשיעורים, לשלם באופן מאובטח ולהשתתף במפגשים המתקיימים בזמן אמת באמצעות זום או גוגל מיט.
            </p>

            <p>
            <h5><b>החזון שלנו</b></h5>
              חזוננו הוא ליצור קהילה חינוכית גלובלית שבה כל אחד יכול ללמוד וללמד ללא מגבלות גיאוגרפיות או זמניות. אנו מאמינים בחשיבות הגישה החופשית לחינוך ובכוחה של הלמידה לשנות חיים.
            </p>

            <p>
            <h5><b>הצטרפו אלינו</b></h5>
              גלו עולמות חדשים של ידע, העשירו את הידע האישי והמקצועי שלכם, והפכו לחלק מקהילת למידה חדשנית ותומכת. אם אתם מורים שמטרתכם להוביל שינוי, להשפיע ולפגוש קהל רחב של תלמידים נלהבים לרכוש ידע, או אם אתם תלמידים המעוניינים להעמיק בלימודים עם מומחים מובילים בתחומם, DoroosOnline היא הבמה האידיאלית בשבילכם.
            </p>

          </div>
        </div>
     
    </div>
   
  );
};

export default About;
