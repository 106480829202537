import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './login_page.css'; 

const LoginPage = () => {
    
    const navigate = useNavigate();
    const [screenHeight, setScreenHeight] = useState(0);

    useEffect(() => {
        setScreenHeight(window.innerHeight - 200); 
    }, []); 
 

    const handleStudentLogin = () => {
        navigate('/login');
    };

    const handleTeacherLogin = () => {
        navigate('/tch_login');
    };

    return (
        <div className="login-container" style={{ minHeight: `${screenHeight}px` }} dir="rtl">
            <h1 className="welcome-title">ברוכים הבאים לפלטפורמה DoroosOnline</h1>
            <br/>
            <p className="subtitle">איך אתם משתמשים ב-DoroosOnline?</p>
        
            <div className="button-group">
                <button 
                    className="login_btn educator-btn"
                    onClick={handleTeacherLogin}
                >
                    מורים
                </button>

                <button 
                    className="login_btn student-account-btn"
                    onClick={handleStudentLogin}
                >
                    תלמידים
                </button>
            </div>
        </div>
    );
};

export default LoginPage;
