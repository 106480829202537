
import React, { useState } from "react"; 
import '../Components/profile.css'
import { sendMessage } from "./db";
import * as Yup from 'yup';
import '../Components/forms.css' 

const loginSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'First name must be at least 2 characters')
    .max(20, 'First name must be at most 20 characters')
    .required('First name is required'),

  lastName: Yup.string()
    .min(2, 'Last name must be at least 2 characters')
    .max(20, 'Last name must be at most 20 characters')
    .required('Last name is required'),

  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
    
  phone: Yup.string()
    .required('Mobile number is required')
    .matches(/^\d+$/, 'Mobile number must contain only numbers')
    .min(10, 'Mobile number must be at least 10 digits long'),

  subject: Yup.string()
    .min(5, 'Subject must be at least 5 characters')
    .max(50, 'Subject must be at most 50 characters')
    .required('Subject is required'),

  message: Yup.string()
    .min(30, 'Message must be at least 30 characters')
    .max(200, 'Message must be at most 200 characters')
    .required('Message is required'),
});


const ContactForm=(props)=> {

  const [errors, setErrors] = useState({});
  const [contactDtls, setContactDtls] = useState({  
    firstName:'', 
    lastName:'',
    email:'',
    phone:'',
    message:'',
    subject:''
  });
//-------------------------------------------------------------------------------------------

  const handleChange = e => {
      const { name, value } = e.target;
      setContactDtls(prevState => ({
          ...prevState,
          [name]: value
      }));
  };
//-------------------------------------------------------------------------------------------

const handleSubmit = async (event) => {
                    
  event.preventDefault();
                     
  try {
        await loginSchema.validate(contactDtls, { abortEarly: false }); 
        
        const msg = await sendMessage(
          contactDtls.firstName, 
          contactDtls.lastName,
          contactDtls.email,
          contactDtls.phone,
          contactDtls.message.replace(/\n/g, "<br>"), 
          contactDtls.subject                        
        )
          
        setContactDtls({firstName:'',lastName:'',email:'',phone:'',message:'',subject:''})
        setErrors({})
        props.alert(msg,'SUCCESS')

  } catch (err) {
      
    if (err.inner) {
      setErrors(
        err.inner.reduce((acc, { path, message }) => {
          acc[path] = message;
          return acc;
        }, {})
      );
    } else {
      props.alert(err.message,'ERROR'); 
    }
  }          
}
//-------------------------------------------------------------------------------------------  

  return (
    
    <form onSubmit={handleSubmit}>
      
      <div className="d-flex flex-column profile-gap p-4 pt-5">  

        <div className="contact-name">
            <div  className="label-100-wrap">                
              <label className="form-label label-100" for="firstName">שם פרטי</label>
              <div>
                <input 
                  type="text" 
                  className={`form-control ${errors.firstName? 'invalid' : ''}`}
                  name="firstName" 
                  value={contactDtls.firstName} 
                  onChange={handleChange} 
                  placeholder="שם פרטי"
                />
                {errors.firstName && <p className="error">{errors.firstName}</p>}
              </div>   
            </div>  

            <div  className="label-100-wrap">                
              <label className="form-label label-100" for="lastName">שם משפחה</label>
              <div>
                <input 
                  type="text" 
                  className={`form-control ${errors.lastName? 'invalid' : ''}`} 
                  name="lastName" 
                  value={contactDtls.lastName} 
                  onChange={handleChange} 
                  placeholder="שם משפחה" 
                /> 
                {errors.lastName && <p className="error">{errors.lastName}</p>}
              </div>   
            </div>
        </div>

        <div  className="label-100-wrap">                
          <label className="form-label label-100" for="email">דואר אלקטרוני</label>
          <div>
            <input  
              type="text" 
              className={`form-control ${errors.email? 'invalid' : ''}`} 
              name="email" 
              value={contactDtls.email} 
              onChange={handleChange} 
              placeholder="דואר אלקטרוני"
            />
            {errors.email && <p className="error">{errors.email}</p>}
          </div>   
        </div>

        <div  className="label-100-wrap">                
          <label className="form-label label-100" for="phone">טלפון נייד</label>
          <div>
            <input  
              type="text" 
              className={`form-control ${errors.phone? 'invalid' : ''}`} 
              name="phone" 
              value={contactDtls.phone} 
              onChange={handleChange} 
              placeholder="טלפון נייד"
            />
            {errors.phone && <p className="error">{errors.phone}</p>}
          </div>   
        </div>

        <div  className="label-100-wrap">                
          <label className="form-label label-100" for="subject">נושא</label>
          <div dir="rtl">
            <input  
              className={`form-control ${errors.subject? 'invalid' : ''}`} 
              name="subject"   
              value={contactDtls.subject} 
              onChange={handleChange}
              placeholder="נושא"
            />
            {errors.subject && <p className="error">{errors.subject}</p>}                           
          </div>   
        </div>

        <div  className="label-100-wrap">                
          <label className="form-label label-100" for="message">הודעה</label>
          <div dir="rtl">
            <textarea 
              className={`form-control ${errors.message? 'invalid' : ''}`} 
              name="message"   
              rows="4" 
              value={contactDtls.message} 
              onChange={handleChange}
            />
            {errors.message && <p className="error">{errors.message}</p>}                           
          </div>   
        </div>

        <div>
          <button type="submit" class="btn btn-outline-secondary btn-sm daily-prog-btn" style={{width:"100%"}}>
           שלח הודעה
          </button>
        </div>                   
                                                                                                                                                          
      </div> 
    </form>     
  );
}

export default ContactForm;
