import React, { useState,useEffect } from "react";
import {Modal} from 'react-bootstrap'; 
import Button from '@mui/material/Button'; 
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './popup_modals.css';
import { FaChalkboardTeacher } from 'react-icons/fa';
import dateFormat, { masks } from "dateformat";
import { updateLesson } from "./db";
import { addDays } from "./time"
import * as Yup from 'yup';
import '../Components/forms.css';
import SelectTime from "./select_time";
import SelectLessonsNum from "./select_lessons_num";
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL; 

const loginSchema = Yup.object().shape({

    subject: Yup.string()
        .min(5, 'Subject must be at least 5 characters')
        .max(40, 'Subject must be at most 40 characters')
        .required('Subject is required'),    

    time: Yup.number()
        .min(1,'Time is required')        
        .required('Time is required'),
    
    lessonsNum: Yup.number()
        .min(1,'Lessons number is required')    
        .required('Lessons number is required'),

    num: Yup.number()
        .min(1,'Lessons number is required')
        .test('Equal test',"equal",
        function(value){ 
            return value === this.parent.lessonsNum
        })
        .required('required'),

    date: Yup.date()       
        .required("Date is required")
        .typeError('Please enter a valid date'),
              
});

const UpdateLesson=(props)=>{ 
    
    const [errors, setErrors] = useState({});
    const [show,setShow]=useState(false);
              
    const [lessonDtls, setLessonDtls] = useState({        
        time:0,
        num:0,
        lessonsNum:0,
        subject:"",       
        sms:false,
        email:false,
        date:addDays(new Date(), 0),
        maxNum:0,
        start:""
    });
//-------------------------------------------------------------------------------------------
        
    useEffect(()=>{

        const fetchLesson = async () => {
           
            try {

                const response = await axios.get(`${baseUrl}/get_lesson_dtls.php`,{
                    params: {
                        lessonId:props.lessonId
                    }
                })
                if(response.data.code>0){
                    
                    setLessonDtls(prevState => ({
                        ...prevState,                       
                        ['subject']:response.data.subject,                          
                        ['num']:response.data.num,
                        ['lessonsNum']:response.data.num,
                        ['time']:response.data.time,
                        ['date']:new Date(response.data.date)                              
                    }))                                                                                                
                }                                
            } 
            catch (error) {        
                alert(error.message,'ERROR') ;                
            }            
        }
               
        if(!props.link)
            setShow(true);
                
        fetchLesson()
       
    },[]);
//------------------------------------------------------------------------------------------- 
 
 const getTime=(time)=>{
              
    setLessonDtls(prevState => ({
        ...prevState,
        ['time']:time,           
    }))      
}
//------------------------------------------------------------------------------------------- 

const getMaxNum=(maxNum)=>{
          
    setLessonDtls(prevState => ({
        ...prevState,            
        ['maxNum']:maxNum,           
    }))      
}
//------------------------------------------------------------------------------------------- 

const getStartTime=(start)=>{
    setLessonDtls(prevState => ({
        ...prevState,        
        ['start']:start
    }))
}
//------------------------------------------------------------------------------------------- 

const getLessonsNum=(num)=>{
    setLessonDtls(prevState => ({
        ...prevState,        
        ['num']:num
    }))
}
//------------------------------------------------------------------------------------------- 

    const handleChange = e => {
        const { name, value } = e.target;
        setLessonDtls(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
//------------------------------------------------------------------------------------------- 

    const handleSubmit = async (event) => {
                                
        event.preventDefault();       
        masks.hammerTime = 'yyyy-mm-dd';
                            
        try {

            await loginSchema.validate(lessonDtls, { abortEarly: false });  
            
            const code=await updateLesson(
                props.lessonId,            
                lessonDtls.subject,               
                lessonDtls.num,
                lessonDtls.time,
                dateFormat(lessonDtls.date, "hammerTime")                   
            )
           
            if( code > 0 ){
                alert("הצלחת לעדכן מפגש !!",'SUCCESS') 
                const   lesson={['sub']:lessonDtls.subject,                               
                                ['date']: dateFormat(lessonDtls.date, "hammerTime"),
                                ['start']:lessonDtls.start,
                                ['num']:lessonDtls.num
                        }
               
                props.updateLessonDtls(lesson,props.index)
            }                  
            else 
                alert("עדכון מפגש נכשל!!",'ERROR')
                            
            setErrors({})
                                                                        
        }catch (err) {
      
            setErrors(err.inner.reduce((acc, { path, message }) => {
              acc[path] = message;
              return acc;
            }, {}));
        }
                
    }
//------------------------------------------------------------------------------------------- 

    const alert=(msg,status)=>{
        closeUpdateLesson()
        props.alert(msg,status)
    }
//------------------------------------------------------------------------------------------- 

    const openUpdateLesson = () => { 
        setShow(true);
    }
//------------------------------------------------------------------------------------------- 
    
    const closeUpdateLesson = () => { 

        setShow(false);

        setLessonDtls({
            time:0,
            num:0,
            subject:"",            
            sms:false,
            email:false,
            date:addDays(new Date(), 1),
            maxNum:0,
            start:""
        })   

        setErrors({})

        if(!props.link)
            props.resetMenu(0);
    }
//------------------------------------------------------------------------------------------- 

    return (  
        <div>        
            {props.link?                  
                <Button 
                    variant="contained" 
                    color="success" 
                    size="small" 
                    onClick={openUpdateLesson}
                >
                    עריכת מפגש
                </Button>                                                                                                    
                :null
            }           
                        
            <Modal 
              show={show}               
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"            
             >  

                <Modal.Header style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}>
                    <div className="d-flex gap-2 align-items-center">
                        <div><FaChalkboardTeacher size={30} /></div>
                        <div className="mt-1"><h5>עריכת מפגש</h5></div>
                    </div>  
                    <button
                        type="button"
                        className="custom-modal-close"
                        aria-label="Close"
                        onClick={closeUpdateLesson}
                    >
                        &times;  {/* Close "X" icon */}
                    </button>                                            
                </Modal.Header>  
                
                <form onSubmit={handleSubmit}>
                    <Modal.Body> 
                    <div className="container">
                        <div class="d-flex flex-wrap gap-4 mt-4">
                                                                                            
                            <div className="label-100-wrap">                    
                                <label className="form-label label-100" for="date">תאריך</label>
                                <div name="date" dir="ltr">              
                                    <DatePicker 
                                        wrapperClassName="date-picker"  
                                        selected={lessonDtls.date} 
                                        onChange={(date)=>{
                                            setLessonDtls(prevState => ({
                                                ...prevState,
                                                date: date,
                                                time:0,
                                                num:1
                                            }));
                                        }} 
                                        popperPlacement="bottom-end"
                                        minDate={addDays(new Date(), 0)}
                                        dateFormat="yyyy-MM-dd"  
                                    />                      
                                </div>
                                {errors.date && <p className="error">{errors.date}</p>}
                            </div>

                            <SelectTime 
                                editing={true}
                                tchId={props.tchId}
                                time={lessonDtls.time} 
                                date={lessonDtls.date} 
                                error={errors.time}
                                lessonId={props.lessonId}
                                setTime={getTime}
                                setLessonsNum={getLessonsNum}
                                setMaxNum={getMaxNum}
                                setStartTime={getStartTime}
                                alert={alert}
                            />

                            <SelectLessonsNum                                 
                                maxNum={lessonDtls.maxNum}
                                num={lessonDtls.num}
                                error={errors.num && <> {errors.num ==='equal'?`Lessons number must be equal to ${lessonDtls.lessonsNum}`:'Lessons number is required'}</>}
                                setLessonsNum={getLessonsNum}
                                alert={alert}
                            />
                                                                                                                
                        </div> 
                         
                        <div className="label-100-wrap mt-5">                
                            <label className="form-label label-100" for="subject">נושא</label>
                            <div>
                                <input  
                                    type="text" 
                                    className={`form-control ${errors.subject? 'invalid' : ''}`} 
                                    name="subject" 
                                    value={lessonDtls.subject} 
                                    onChange={handleChange} 
                                    placeholder="נושא שיעור"
                                />
                                {errors.subject && <p className="error">{errors.subject}</p>}
                            </div>   
                        </div> 
                        
                        <div class="mt-4">
                            <div class="form-check" > 
                                <input 
                                    class="form-check-input" 
                                    type="checkbox" 
                                    name="sms" 
                                    checked={lessonDtls.sms}
                                    onClick={()=>{setLessonDtls(prevState => ({
                                        ...prevState,
                                        sms: !prevState.sms
                                      }));
                                    }}                                     
                                />
                                <label class="form-check-label" for="sms">
                                    שלח הודעת SMS למשתתפים בקורס.
                                </label>                                
                            </div>

                            <div class="form-check">                                                                 
                                <input 
                                    class="form-check-input" 
                                    type="checkbox"                                       
                                    name="email" 
                                    checked={lessonDtls.email} 
                                    onClick={()=>{setLessonDtls(prevState => ({
                                        ...prevState,
                                        email: !prevState.email
                                      }));
                                    }}                                    
                                />                                        
                                <label class="form-check-label" for="email">
                                    שלח הודעת דוא"ל למשתתפים בקורס.
                                </label>                             
                            </div>
                        </div>    
                    </div>
                    </Modal.Body>  

                    <Modal.Footer>
                        <div className="d-flex gap-3 me-3 mb-2">                         
                            <Button variant="contained"  color="error"  size="small" onClick={closeUpdateLesson}>ביטול</Button>  
                            <Button type="submit" variant="contained" color="success" size="small" >שמירה</Button>                         
                        </div>
                    </Modal.Footer> 
                </form>        
            </Modal> 
        </div>        
    )  
  } 
  
export default UpdateLesson;
