import React, { useState, useEffect } from "react";
import PageTitle from "../Components/page_title";
import "./About.css"; // Ensure to create a corresponding CSS file

const TermsPage = (props) => {
  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    setScreenHeight(window.innerHeight - 200);
  }, []);

  return (
    <div className="container x_container" style={{ minHeight: `${screenHeight}px` }}>
      <div>
        <div className="mt-4">
          <PageTitle text="תנאים והגבלות" />
        </div>

        <div className="about-container">
          <p><b>1. קבלת התנאים</b><br />
            בהיכנסך לאתר האינטרנט שלנו ובשימושך בשירותים המוצעים בו, הנך מצהיר ומאשר כי קראת והבנת את תנאי השימוש הללו וכי הינך מסכים להיות כפוף להם. אם אינך מסכים לאחד או יותר מהתנאים הללו, אינך רשאי להשתמש בשירותינו.
          </p>
          <p><b>2. הרשמה</b><br />
            משתמשים מחויבים לספק מידע הרשמה מדויק, מלא ועדכני. באחריותך לשמור על סודיות סיסמתך ולדאוג לביטחון חשבונך.
          </p>
          <p><b>3. אימות חשבון</b><br />
            על המשתמשים לספק כתובת דוא"ל ומספר טלפון נייד תקפים לצורכי אימות חשבון. קוד אימות יישלח לדוא"ל ולמספר הטלפון שסופקו. על המשתמשים להזין את הקוד להשלמת תהליך האימות. חשבונות שאינם מאומתים לא יזכו לגישה מלאה לשירותינו.
          </p>
          <p><b>4. חובות המשתמש</b><br />
            המשתמשים מחויבים להשתמש בשירותינו באחריות ואסור להם להשתמש בשירותינו לצורך הטרדה, איומים או השמצה כלפי כל אדם או גוף.
          </p>
          <p><b>5. תוכן</b><br />
            המורים והמרכזים הלימודיים נושאים באחריות הבלעדית לתוכן קורסיהם ושיעוריהם. הפלטפורמה אינה מפקחת ואינה שולטת בתוכן המפורסם ולכן אינה ערבה לדיוק, לשלמות או לאיכות התוכן.
          </p>
          <p><b>6. תשלומים</b><br />
            כל התשלומים חייבים להתבצע באמצעים המצוינים באתר.
          </p>
          <p><b>7. החזרים וזיכויים</b><br />
            למורה יש את הזכות לבטל שיעורים לפי שיקול דעתו. במקרה בו תלמיד שילם עבור שיעור שלא התקיים או שיעור שהתבטל מכל סיבה שהיא, הסכום ששולם יזוכה בחשבונו באתר שלנו ויוכל לשמש לרכישות עתידיות של שיעורים בלבד. הזיכוי אינו ניתן להעברה, משיכה או המרה למזומן.
          </p>
          <p><b>8. דירוג וביקורות</b><br />
            המשתמשים זכאים להשאיר ביקורות ודירוגים כנים ומכבדים עבור המורים על בסיס חוויית הלמידה שלהם. משוב זה מסייע לשמור על הסטנדרטים הגבוהים של הלמידה ב-DoroosOnline.
          </p>
          <p><b>9. שיעורים פרטיים</b><br />
            לתלמידים יש אפשרות להזמין שיעורים פרטיים בקורס לשיעורים פרטיים בזמנים שבהם המורה יהיה זמין.
          </p>
          <p><b>10. מרכזי לימוד ומורים משויכים</b><br />
            מרכזי לימוד יכולים להוסיף את קורסיהם לפלטפורמה ולשייך אליהם מורים. מורים משויכים נדרשים להוסיף שיעורים בודדים בתוך הקורס.
          </p>
          <p><b>11. מורים עצמאיים</b><br />
            מורים יכולים להירשם ולהתחיל ליצור קורסים באופן עצמאי בפלטפורמה. עליהם להגיש את כל המסמכים הנדרשים כדי להוכיח את כישוריהם.
          </p>
          <p><b>12. תשלום למורים</b><br />
            קורסים משויכים למרכז לימוד: מורים המשויכים למרכז לימוד ומעבירים שיעורים במסגרת קורסי המרכז יקבלו את תשלום השיעורים ישירות מהמרכז הרלוונטי.
          </p>
          <p><b>13. יחסי עובד-מעביד</b><br />
            אין לראות במורים המשתמשים בפלטפורמה DoroosOnline כעובדי הפלטפורמה. המורים פועלים כעצמאים ונושאים באחריות הבלעדית לכל החובות והזכויות הנובעות מכך.
          </p>
          <p><b>14. איסור תיאום שיעורים מחוץ לפלטפורמה</b><br />
            חל איסור מוחלט על תיאום שיעורים בין מורים לתלמידים מחוץ לפלטפורמה.
          </p>
          <p><b>15. התנהגות משתמש וקווים מנחים קהילתיים</b><br />
            כל המשתמשים, כולל מורים, חייבים להקפיד על הקווים המנחים שלנו לקהילה.
          </p>
          <p><b>16. קניין רוחני</b><br />
            תוכן האתר, למעט התוכן שנוסף על ידי המורים, הוא בבעלות או ברישיון האתר ומוגן על ידי חוקים של קניין רוחני.
          </p>
          <p><b>17. שינויים בתנאים</b><br />
            אנו שומרים לעצמנו את הזכות לשנות תנאים אלה בכל עת.
          </p>
          <p><b>18. כתב ויתור על אחריות והגבלת אחריות</b><br />
            השירותים שלנו ניתנים על בסיס "כפי שהם".
          </p>
          <p><b>19. קבלת תנאי השימוש</b><br />
            להשלמת תהליך ההרשמה ולהשתמש בפלטפורמה שלנו, על המשתמשים לקבל תנאי שימוש אלו.
          </p>
          <p><b>20. הדין החל</b><br />
            תנאים אלו כפופים ומתפרשים בהתאם לחוקי האזור שבו פועלת DoroosOnline.
          </p>
          <p><b>21. פרטי יצירת קשר</b><br />
            אם יש לך שאלות בנוגע לתנאי שימוש אלו, אנא פנה אלינו בכתובת support@doroosonline.com.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsPage;
