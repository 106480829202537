import React, { useState, useEffect, useRef } from "react";
import PageTitle from "../Components/page_title";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import Divider from "@mui/material/Divider";
import ReactLoading from "react-loading";
import axios from "axios";
import { Alert } from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";
import "../Components/alert.css";
import ReportByMonth from "../Components/report_by_month";

const baseUrl = process.env.REACT_APP_BASE_URL;

const DoneLessons = (props) => {
  const [reportItems, setReportItems] = useState([]);
  const total = useRef(0);
  const [screenHeight, setScreenHeight] = useState(0);
  const [fire, setFire] = useState(false);
  const [commission, setCommission] = useState(0);
  const [taxRate, setTaxRate] = useState(0);

  const [alert, setAlert] = useState({
    msg: null,
    status: "SUCCESS",
  });

  useEffect(() => {
    setScreenHeight(window.innerHeight - 200);
  }, []);

  //-------------------------------------------------------------------------------------------
  const setDateRange = async (s_date, e_date) => {
    total.current = 0;

    try {
      setFire(true);
      const response = await axios.get(`${baseUrl}/tch_done_meetings_report.php`, {
        params: {
          sDate: s_date,
          eDate: e_date,
          tchId: props.userId,
        },
      });

      if (
        response.data.status === "success" &&
        Array.isArray(response.data.lessons) &&
        response.data.lessons.length
      ) {
        setReportItems(response.data.lessons);
        setCommission(response.data.commission);
        setTaxRate(response.data.tax_rate);
      } else if (response.data.status === "info" && response.data.message) {
        set_alert(response.data.message, "INFO");
        setReportItems([]);
      } else if (response.data.status === "error" && response.data.message) {
        throw new Error(response.data.message);
      } else {
        setReportItems([]);
      }
    } catch (error) {
      set_alert(error.message, "ERROR");
      setReportItems([]);
    } finally {
      setFire(false);
    }
  };

  //-------------------------------------------------------------------------------------------
  const calculateTotal = () => {
    return reportItems.reduce((sum, item) => sum + parseFloat(item.total_income), 0).toFixed(2);
  };

  const calculateCommission = () => {
    return (calculateTotal() * commission/100).toFixed(2);
  };

  const calculateTax = () => {
    return ((calculateTotal() - calculateCommission()) * taxRate/100).toFixed(2);
  };

  const calculateNetIncome = () => {
    return (calculateTotal() - calculateCommission() - calculateTax()).toFixed(2);
  };

  //-------------------------------------------------------------------------------------------
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //-------------------------------------------------------------------------------------------
  const set_alert = (msg, status) => {
    setAlert({
      msg: msg,
      status: status,
    });
    goToTop();
  };

  //-------------------------------------------------------------------------------------------
  return (
    <div className="container mb-4" style={{ minHeight: `${screenHeight}px` }}>
      <div className="mt-4">
        <PageTitle text="דוח שיעורים שבוצעו" />
      </div>

      <div className="mt-5 ms-2">
        {/* Component to pick the date range and pass to setDateRange */}
        <ReportByMonth setDateRange={setDateRange} alert={set_alert} />
      </div>

      <div className="mt-4 mb-4">
        <Divider />
      </div>

      <div className="d-flex flex-column gap-2 position-relative">
        {fire ? (
          <div
            className="position-absolute start-50 translate-middle-x mt-5"
            style={{ zIndex: "1" }}
          >
            <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70} />
          </div>
        ) : null}

        {alert.msg != null ? (
          <div className="mb-2" dir="ltr">
            <Alert
              severity={alert.status.toLowerCase()} // Set severity based on the alert status ('success', 'error', etc.)
              variant="outlined"
              onClose={() => {
                setAlert({ msg: null, status: "SUCCESS" }); // Reset alert
              }}
            >
              <AlertTitle>
                <span className="bold-text">
                  {alert.status === "SUCCESS" ? "Success" : "Error"}
                </span>
              </AlertTitle>
              <span className="bold-text">{alert.msg}</span>
            </Alert>
          </div>
        ) : null}

        {/* Table for displaying retrieved data */}
        {reportItems.length > 0 && (
          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <thead className="table-light">
                <tr>
                  <th>#</th>
                  <th>נושא</th>
                  <th>תאריך</th>
                  <th>מס' שיעורים</th>
                  <th>מחיר</th>
                  <th>מס' משתתפים</th>
                  <th>סה"כ ₪</th>
                </tr>
              </thead>
              <tbody>
                {reportItems.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.subject}</td>
                    <td>{item.lesson_date}</td>
                    <td>{item.num_of_lessons}</td>
                    <td>{item.lesson_price}</td>
                    <td>{item.num_students}</td>
                    <td>{item.total_income.toFixed(2)}</td>
                  </tr>
                ))}
                {/* Add rows at the end to display the total, commission, tax, and net income */}
                <tr style={{backgroundColor:'lightgray'}}>
                  <td colSpan="5"></td>
                  <td className="fw-bold">סה"כ תשלומים :</td>
                  <td className="fw-bold">{calculateTotal()} ₪</td>
                </tr>
                <tr style={{backgroundColor:'lightgray'}}>
                  <td colSpan="5"></td>
                  <td className="fw-bold">עמלה ({commission.toFixed(2)}%) :</td>
                  <td className="fw-bold">{calculateCommission()} ₪</td>
                </tr>
                <tr style={{backgroundColor:'lightgray'}}>
                  <td colSpan="5"></td>
                  <td className="fw-bold">מס הכנסה ({taxRate.toFixed(2)}%) :</td>
                  <td className="fw-bold">{calculateTax()} ₪</td>
                </tr>
                <tr style={{backgroundColor:'lightgray'}}>
                  <td colSpan="5"></td>
                  <td className="fw-bold">סה"כ נטו לבנק :</td>
                  <td className="fw-bold text-primary">{calculateNetIncome()} ₪</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default DoneLessons;
