import FacebookBtn from "../Components/facebookLogin";
import GoogleBtn from "../Components/googleLogin";
import { GoogleOAuthProvider } from '@react-oauth/google';

const Social = (props) => {
 
    return(
                    
        <div className="d-flex flex-column gap-3">
            
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <GoogleBtn   
                    user={props.user} 
                    connect={props.connect} 
                    disabled={props.disabled}
                    turnFireOn={props.turnFireOn}
                    turnFireOff={props.turnFireOff}
                    mode={props.mode}
                    alert={props.alert}
                />
            </GoogleOAuthProvider>
            
            <div>
                <FacebookBtn 
                    user={props.user} 
                    connect={props.connect} 
                    disabled={props.disabled}
                    turnFireOn={props.turnFireOn}
                    turnFireOff={props.turnFireOff}
                    mode={props.mode}
                    alert={props.alert}
                />
            </div>                                                        

        </div>
                  
    )};
  
export default Social;
