import React, { useState } from "react";
import {Modal} from 'react-bootstrap'; 
import Button from '@mui/material/Button'; 
import './popup_modals.css';
import { IoMdSettings } from "react-icons/io";
import * as Yup from 'yup';
import { setTeacherZoomKeys } from "./db";
import '../Components/forms.css'; 


const getValidationSchema = () => {
    let schema = Yup.object().shape({

        CLIENT_ID: Yup.string()
        .matches(/^[A-Za-z0-9_-]{22}$/,"Zoom secret key is not correct")
        .required("Zoom client ID is required" ),

        SECRET_KEY: Yup.string()
        .matches(/^[A-Za-z0-9]{32}$/,"Zoom secret key is not correct")
        .required("Zoom secret key is required"),

        ACCOUNT_ID: Yup.string()
        .matches(/^[A-Za-z0-9_-]{22}$/,"Zoom account ID is not correct")
        .required("Zoom account ID is required"),
            
    });
  
    return schema;
};

const ZoomSettings=(props)=>{ 
    
    const [errors, setErrors] = useState({}); 
    const [show,setShow]=useState(false);
              
    const [keys, setKeys] = useState({ 
        ACCOUNT_ID:'',
        CLIENT_ID:'',
        SECRET_KEY:''       
    });

//-------------------------------------------------------------------------------------------

    const handleChange = e => {
        const { name, value } = e.target;
        setKeys(prevState => ({
            ...prevState,
            [name]: value
        }));
    };    
//------------------------------------------------------------------------------------------- 

    const handleSubmit = async (event) => {
        event.preventDefault();       
        const schema = getValidationSchema();  

        try {
           
            await schema.validate(keys, { abortEarly: false });

            const result = await setTeacherZoomKeys(
                props.tchId,
                keys.ACCOUNT_ID,
                keys.CLIENT_ID,
                keys.SECRET_KEY,
            );

            alert(result.message,'SUCCESS');

            setKeys({
                ACCOUNT_ID: '',
                CLIENT_ID: '',
                SECRET_KEY: ''
            });

            setErrors({});

        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                setErrors(err.inner.reduce((acc, { path, message }) => {
                    acc[path] = message;
                    return acc;
                }, {}));
            } else {

                alert(err.message || "An error occurred.",'ERROR');
            } 
        }
    }
//------------------------------------------------------------------------------------------- 

    const alert=(msg,status)=>{
        closeZoomSettings()
        props.alert(msg,status)
    }
//------------------------------------------------------------------------------------------- 

    const openZoomSettings = () => { 
        setShow(true);
    }
//------------------------------------------------------------------------------------------- 

    const closeZoomSettings = () => { 

        setShow(false);

        setKeys({
            ACCOUNT_ID:'',
            CLIENT_ID:'',
            SECRET_KEY:''
        })  

        setErrors({})
    }
//------------------------------------------------------------------------------------------- 

    return (  
        <div dir="ltr">        

            <button type="button" class="btn btn-outline-secondary btn-sm daily-prog-btn" onClick={openZoomSettings} style={{width:"100%"}}>הגדרת חשבון זוום</button>          
                        
            <Modal 
              show={show}  
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"            
            >  

                <Modal.Header style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}>
                    <div className="d-flex gap-2 align-items-center">
                        <div><IoMdSettings size={30} /></div>
                        <div className="mt-1"><h5>הגדרת חשבון זוום</h5></div>
                    </div>
                    <button
                        type="button"
                        className="custom-modal-close"
                        aria-label="Close"
                        onClick={closeZoomSettings}
                    >
                        &times;  {/* Close "X" icon */}
                    </button>                                              
                </Modal.Header>  
                
                <form onSubmit={handleSubmit}>
                    <Modal.Body> 
                        <div className="container" dir="ltr">                                                                                       
                            <div className="mb-2 mt-2">
                                <input  
                                    type="text" 
                                    className={`form-control ${errors.ACCOUNT_ID? 'invalid' : ''}`} 
                                    name="ACCOUNT_ID" 
                                    value={keys.ACCOUNT_ID} 
                                    onChange={handleChange} 
                                    placeholder="ZOOM ACCOUNT ID"
                                />
                                {errors.ACCOUNT_ID && <p className="error">{errors.ACCOUNT_ID}</p>}
                            </div>
                                                                                      
                            <div className="mb-2">
                                <input  
                                    type="text" 
                                    className={`form-control ${errors.CLIENT_ID? 'invalid' : ''}`} 
                                    name="CLIENT_ID" 
                                    value={keys.CLIENT_ID} 
                                    onChange={handleChange} 
                                    placeholder= "ZOOM CLIENT ID" 
                                />
                                {errors.CLIENT_ID && <p className="error">{errors.CLIENT_ID}</p>}
                            </div>   
                                                    
                            <div>
                                <input  
                                    type="text" 
                                    className={`form-control ${errors.SECRET_KEY? 'invalid' : ''}`} 
                                    name="SECRET_KEY" 
                                    value={keys.SECRET_KEY} 
                                    onChange={handleChange} 
                                    placeholder="ZOOM SECRET KEY"
                                />
                                {errors.SECRET_KEY && <p className="error">{errors.SECRET_KEY}</p>}
                            </div> 

                        </div>
                    </Modal.Body>  

                    <Modal.Footer>
                        <div className="d-flex gap-3 me-3 mb-2">                         
                            <Button variant="contained"  color="error"  size="small" onClick={closeZoomSettings}>ביטול</Button>  
                            <Button type="submit" variant="contained" color="success" size="small" >שמירה</Button>                         
                        </div>
                    </Modal.Footer> 
                    
                </form>        
            </Modal> 
        </div>        
    )  
  } 
  
export default ZoomSettings;  
