import React, {useState,useEffect} from "react";
import axios from 'axios';
import {Modal} from 'react-bootstrap'; 
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CourseIcon from "./course_icon";
import { BsImages  } from 'react-icons/bs';
import Pagination from '@mui/material/Pagination';
import ReactLoading from 'react-loading';
import UploadPhotoFile from "./upload_photo";
import { pairUserPhoto } from "./db";
import '../Components/forms.css';

const baseUrl = process.env.REACT_APP_BASE_URL;
const win=8;

const SelectProfilePic=(props)=>{ 
          
    const [show,setShow]=useState(false);   
    const [checked,setChecked]=useState(0);
    const [page, setPage] = useState(1);
    const [pics,setPics]=useState([]);
    const [fire,setFire]=useState(false);
    const [count, setCount] = useState(0);
  
    useEffect(()=>{

      const fetchPhotos = async () => {
        try {

          const response = await axios.get(`${baseUrl}/profile_photos.php`,{
            params: {                        
              userId:props.userId                                                                         
            }
          })
                              
          if(response.data.status==='success'){
            setPics(response.data.pics);
            setCount(response.data.pics.length);
          } else {
            setPics([]);
            if(response.data.status==='error'){
              throw new Error(response.data.message)
            }
          }
            
        } 
        catch (error) {
          alert(error.message,'ERROR') ;
        }        
      }

      fetchPhotos();
                       
    },[]);
//-------------------------------------------------------------------------------------------

  const alert=(msg,status)=>{
    closeProfilePicModal()
    props.alert(msg,status)
  }
//------------------------------------------------------------------------------------------- 

  function openProfilePicModal(){ 
    setShow(true)
  };
//-------------------------------------------------------------------------------------------

  function closeProfilePicModal(){ 
    setShow(false);
    
  };
//-------------------------------------------------------------------------------------------    

  const getValueFromPic=(value)=>{
    setChecked(value);
  }
//-------------------------------------------------------------------------------------------

  const handleChange = (_,page)=> {
    
    if (page !== null) 
      setPage(page);     
  }
//-------------------------------------------------------------------------------------------

  const isLoding = (flag) => {
    setFire(flag)

  }
//-------------------------------------------------------------------------------------------
  
  const addPhoto= async (url)=>{
     
    const id = await pairUserPhoto(props.userId,url)
    setPics((prevArr)=> [...prevArr,{id:id,file:url}]);
    setCount(prev => prev + 1);
    return(id)
     
  }
//-------------------------------------------------------------------------------------------

  const handleProfilePicChange= async () => {
    
    try {
      const response = await axios.get(`${baseUrl}/update_profile_pic.php`,{
        params: {                        
          userId:props.userId,
          picId:checked                                                           
        }
      })
      
      if( response.data.id > 0 ){         
        props.changeProfilePic(response.data.url)
        closeProfilePicModal()
      }
      else
        alert("עדכון התמונה נכשל!!",'ERROR')
    } 
    catch (error) {
      alert(error.message,'ERROR') ;
    }      
  }
//-------------------------------------------------------------------------------------------

  const extractFilenameFromUrl=(url)=> {
      
    const decodedUrl = decodeURIComponent(url);
    const urlObj = new URL(decodedUrl);
    
    const pathname = urlObj.pathname;
    const filename = pathname.split('/').pop();
    
    return filename;
  }
//-------------------------------------------------------------------------------------------

  const deletePhoto = async () => {

    const photoToDelete = pics.find(pic => pic.id === checked);
    
    try {
        setFire(true)
        const response = await axios.get(`${baseUrl}/delete_profile_pic.php`, {
            params: { 
              id: checked,
              file:extractFilenameFromUrl(photoToDelete.file) 
            }
        });

        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        const updatedPics = pics.filter(pic => pic.id !== checked);
        setPics(updatedPics);
        setCount(prev => prev - 1);

        setChecked(0);

        const newTotalPages = Math.ceil(updatedPics.length / win);

        if (page > newTotalPages) {
            setPage(newTotalPages > 0 ? newTotalPages : 1);
        }

    } catch (error) {
      alert(`Error deleting photo: ${error.message}`,'ERROR');
    } finally{
      setFire(false)
    }
  }
//-------------------------------------------------------------------------------------------

  return (  
    <div>        
      <a href="#" onClick={openProfilePicModal}>בחירת תמונת פרופיל</a>
      <Modal 
          show={show}  
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"            
      >  
          <Modal.Header style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}>
            <div className="d-flex gap-2 align-items-center">
              <div><BsImages size={30} /></div>
              <div className="mt-1"><h5>בחירת תמונת פרופיל</h5></div> 
            </div>  
            <button
                type="button"
                className="custom-modal-close"
                aria-label="Close"
                onClick={closeProfilePicModal}
            >
                &times;  {/* Close "X" icon */}
            </button>           
          </Modal.Header>

          <Modal.Body>
            <div className="container position-relative">

              {fire?
                <div className="position-absolute top-50 start-50 translate-middle-x" style={{zIndex: "1",top:"300",left:'300'}}>
                    <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
                </div>
                :
                null
              } 

              <div className="row justify-content-center gap-2" style={{ minHeight: '100%' }}>

                <div className="col-lg-3 d-flex flex-column justify-content-center align-items-center border rounded pb-3" style={{borderColor:"#d3d3d3"}}>                    
                  <UploadPhotoFile  
                    isLoding={isLoding} 
                    addPhoto={addPhoto} 
                    userId={props.userId}
                    count={count} 
                    alert={alert}
                  />
                </div>

                <div className="col-lg-8 border rounded" style={{borderColor:"#d3d3d3"}}> 

                  <div className="d-flex flex-wrap gap-1 mt-2" style={{ minHeight: 230, height: 'auto' }}>
                    { 
                      pics.slice((page-1)*win,(page-1)*win+win).map((pic)=>(                  
                        <CourseIcon 
                          value={pic.id} 
                          checked={checked} 
                          icon={pic.file} 
                          getValueFromIcon={getValueFromPic}
                          alert={alert}
                        />
                    ))}                               
                  </div>

                  
                  <div className="mt-3 mb-2 d-flex justify-content-between align-items-center" dir="ltr">

                    <IconButton 
                      aria-label="delete" 
                      size="large"                                                
                      onClick={deletePhoto}
                      disabled={checked===0}
                    >
                      <DeleteIcon fontSize="inherit"/>
                    </IconButton>

                    <Pagination 
                      count={Math.ceil(pics.length/win)} 
                      variant="outlined" 
                      shape="rounded" 
                      color="primary" 
                      page={page} 
                      onChange={handleChange} 
                    />  

                  </div>
                </div>

              </div>

            </div>  
          </Modal.Body>

          <Modal.Footer>  
            <div className="d-flex gap-2 me-3">

                <Button 
                  variant="contained" 
                  color="error" 
                  size="small" 
                  onClick={closeProfilePicModal}
                >
                  סגור
                </Button> 

                <Button 
                  variant="contained" 
                  color="success" 
                  size="small" 
                  onClick={handleProfilePicChange} 
                  disabled={checked===0}
                >
                  בחירה
                </Button>

            </div>  
          </Modal.Footer> 
          
      </Modal> 
    </div>        
  )  
} 
  
export default SelectProfilePic;  
