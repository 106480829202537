
import React, { useEffect, useState } from "react";
import BasicRating  from "./rating";
import Checkbox from '@mui/material/Checkbox';
import '../Components/cart_item.scss'
import axios from 'axios';
import noIcon from '../Assets/Images/logo192.png'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const baseUrl = process.env.REACT_APP_BASE_URL; 

const ItemBox = (props) =>{
  const [checked, setChecked] = useState(false);
 
  useEffect(()=>{

   setChecked(props.checked)

  },[props.checked]) 

//-------------------------------------------------------------------------------------------

  const handleChange = () => {
      
    setChecked(prev=>!prev)
    
    var price=props.price;
    
    if(checked)
      price=price*-1;

    const lessonDtls={
      id:props.lessonId,
      price:props.price,
      amount:props.num,
      teacher:props.teacher,
      subject:props.subject, 
    }

    props.updateTotal(lessonDtls,price*props.num)

  }
//-------------------------------------------------------------------------------------------     

  const deleteItem = async () => {
    try {
      const response = await axios.get(`${baseUrl}/delete_item_from_cart.php`,{
        params: {                        
          stdId:props.stdId,
          lessonId:props.lessonId                                                                    
        }
      })
     
      if(response.data.code > 0){        
        if(checked)
          props.updateTotal({id:props.lessonId},props.price*props.num*-1)
                
        props.removeItem(props.lessonId)
      }
      else
        props.alert("מחיקת פריט מסל הקניות נכשלה,נסה שנית!!",'ERROR')  
    } 
    catch (error) {

      props.alert(error.message,'ERROR') ;
      
    }

  }
//-------------------------------------------------------------------------------------------
  
  return (

    <div className="cart-item" style={{background: props.bgcolor}}>
              
      <div>          
        <Checkbox checked={checked} color="secondary" onChange={handleChange}/>                     
      </div>

      <div className="subject-icon-container">
          <div className="frame">
              <img 
                  src={props.photo != null ? props.photo : noIcon} 
                  alt="subject icon" 
                  className="subject-icon" 
              />
          </div>
        </div>

      <div className="subject-title-container">
        <p className="subject-title">{props.subject}</p>
      </div>

      <div className="rating-container">
        <div className="teacher-rating">
          <p className="teacher-name">{props.teacher}</p>
          <span dir="ltr">
            <BasicRating 
              value={props.rating} 
              ratings={props.ratings} 
              readOnly={true}
            />
          </span>
        </div>
      </div>
      
      <div className="date-time">
        <p className="date">{props.date}</p>
        <p className="time">{`${props.start} - ${props.end}`}</p>
      </div>
      
      <div class="price">{`₪${props.price*props.num}`}</div>

      <IconButton 
        aria-label="delete" 
        size="large"                                                
        onClick={deleteItem}
      >
        <DeleteIcon fontSize="inherit"/>
      </IconButton>
                                                                                       
    </div>                                               
  );
};

export default ItemBox;


            
