import React, { useEffect, useState } from "react";
import { BsFillCartPlusFill } from 'react-icons/bs';
import { BiCameraMovie } from 'react-icons/bi';
import CountdownTimer from "./countdown_timer";
import {dateTimeToMs} from "../Components/time";
import { useCountdown } from "./use_count_down";
import { newZoomMeeting,getAvailableMeetings,setLessonDone} from "./db";
import ReactLoading from 'react-loading';

const StartLesson = (props) =>{    
    const [days, hours, minutes, seconds] = useCountdown(dateTimeToMs(props.date,props.end));
    const [fire,setFire]=useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [meetingDtls,setMeetingDtls]=useState({
        meetingUrl:'',
        startTime:'',
        duration:0 
    });
        
    useEffect(()=>{

        const fetchData = async () => {
            try {

                if(days + hours + minutes + seconds <= 0) {          
                    props.endOfLesson();            
                } else {

                    if(meetingDtls.meetingUrl) {

                        const now = new Date();
                        const start = new Date(meetingDtls.startTime);
                        const end = new Date(start.getTime() + meetingDtls.duration * 60 * 1000);
                       
                        if(now > end) {
                            
                            setMeetingDtls({
                                meetingUrl: '',
                                startTime: '',
                                duration: 0
                            });
                        }

                    } else {
                                                
                        if (!meetingDtls.meetingUrl && !isLoading){

                            setIsLoading(true);
                            
                            const {code, hostUrl, joinUrl, startTime, duration, tch_done} = await getAvailableMeetings(props.lessonId, props.mode);

                            setIsLoading(false);
                        
                            if(code > 1) {
                                let url = '';
                                if(props.user === 'TCH') {
                                    url = hostUrl;
                                    if(fire === true) {
                                        window.open(url, "_blank");
                                        setTimeout(() => setFire(false), 5000);
                                    }
                                } else {
                                    if(tch_done === 1) {
                                        url = joinUrl;
                                    }
                                }
                                
                                setMeetingDtls({
                                    meetingUrl: url,
                                    startTime: startTime,
                                    duration: duration
                                });
                            }                            
                        }
                    }
                }
            } catch (error) {
                setIsLoading(false);
                //props.alert(`Error fetching data:${error.message}`,'ERROR');
            }
        };
        
        fetchData();
                      
    },[seconds])
//---------------------------------------------------------------------------------------------

const startZoomMeeting = async () => {

    try {

        if(props.user !== 'SCH'){
            const response= await setLessonDone(props.lessonId, props.user, props.mode, props.userId)
        }

        if (!meetingDtls.meetingUrl) {
            if (props.user === 'TCH') {

                setFire(true); 
                const responseData = await newZoomMeeting(props.lessonId, props.userId, props.mode, props.topic, props.platform);

                if (responseData.code === 0 && responseData.redirectUrl) {

                    window.open(responseData.redirectUrl, '_blank','width=550,height=600,left=359,top=100');
                }                
                
            }
        } else {
            setFire(true); 
            window.open(meetingDtls.meetingUrl, "_blank");
            setTimeout(() => setFire(false), 5000);  
        }

    } catch (error) {
        setFire(false); 
        props.alert(`An error occurred:${error.message}`,'ERROR');
    }
}

//---------------------------------------------------------------------------------------------

    return(
        <> 
            {   props.cancelled?
            
                <button type="button" class="btn btn-primary btn-sm" style={{borderRadius:"20px",width:"130px"}} disabled>                                                         
                    <span>השיעור התבטל</span>                                                
                </button>
                : 
                <>            
                    {((days + hours + minutes + seconds > 0) && props.status!=='PAID' && props.user==='STD') || ((days + hours + minutes + seconds <= 0) && props.status!=='PAID' && props.user==='STD' && props.recording===1)?
                        <>
                            {props.status==='IN_CART'?                  
                                <button type="button" class="btn btn-primary btn-sm" style={{borderRadius:"20px",width:"130px"}} disabled>
                                    <div className="position-relative">                                     
                                        <span className="float-start ps-2">בתוך הסל</span>
                                        <span className="position-absolute" style={{top:"-2px",left:"2px"}}><BsFillCartPlusFill size={15}/></span>                                                                                                   
                                    </div>
                                </button>
                                :
                                <button type="button" class="btn btn-success  btn-sm" style={{borderRadius:"20px",width:"130px"}} onClick={props.addLessonToCart}>
                                    <div className="position-relative">                                                
                                        <span className="float-start ps-2">הוסף לסל</span>
                                        <span className="position-absolute" style={{top:"-2px",left:"2px"}}><BsFillCartPlusFill size={15}/></span>                                                                                                   
                                    </div>
                                </button>                    
                            }
                        </> 
                        : props.timer?                                                                       
                        <>
                            <CountdownTimer 
                                targetDate={dateTimeToMs(props.date,props.start)}                                 
                                countdownStopped={props.startOfLesson} 
                                alert={props.alert}                       
                            />
                        </>                        
                        :
                        <>                    
                            { days + hours + minutes + seconds > 0?                        
                                <button 
                                    type="button" 
                                    className={`btn ${(!meetingDtls.meetingUrl && props.user === 'TCH') ? 'btn-warning' : 'btn-success'} btn-sm`} 
                                    style={{borderRadius:"20px",width:"130px"}}
                                    onClick={startZoomMeeting}
                                    disabled={(!meetingDtls.meetingUrl && props.user !== 'TCH') || fire}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: fire ? 'space-around' : 'center', width: '100%' }}>
                                        
                                        {fire ? 
                                            <ReactLoading type="spin" color="rgb(53, 126, 221)" height={20} width={20} />
                                            :
                                            null
                                        }
                                        <span>התחל עכשיו</span>
                                    </div>                              
                                </button>                                
                                :
                                <>  
                                    {props.recording===0 && props.done?
                                        <button type="button" class="btn btn-danger btn-sm" style={{borderRadius:"20px",width:"130px"}} disabled>                                                         
                                            <span>השיעור הסתיים</span>          
                                        </button>
                                        : props.recording===1?
                                        <a href={props.zoom} target='_blank'>
                                            <button type="button" class="btn btn-secondary btn-sm" style={{borderRadius:"20px",width:"130px"}}>                                                         
                                                <div className="position-relative">                                                
                                                    <span className="float-start ps-1">שיעור מוקלט</span>
                                                    <span className="position-absolute" style={{top:"-3px",left:"3px"}}><BiCameraMovie size={20}/></span>                                                                                                   
                                                </div>                                              
                                            </button>
                                        </a>
                                        :
                                        <button type="button" class="btn btn-danger btn-sm" style={{borderRadius:"20px",width:"130px"}} disabled>                                                         
                                            <span>השיעור לא התקיים</span>          
                                        </button>
                                    }
                                </>
                            }
                        </>
                    }
                </>                                                      
            }
        </>           
    )
}
export default StartLesson;  
