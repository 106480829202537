import  React, {useState,useEffect,useRef} from "react";
import ProfileStatus from "../Components/profile_status";
import PageTitle from "../Components/page_title";
import Settings from "../Components/settings";
import ChangePassword from "../Components/change_password";
import AccountDtls from '../Components/account_dtls';
import { Alert } from "@mui/material";
import AlertTitle from '@mui/material/AlertTitle';
import '../Components/alert.css'; 

const TchProfile = (props) => {
  const [screenHeight, setScreenHeight] = useState(0);

  const [alert,setAlert]=useState({
    msg:null,
    status:'SUCCESS',
  });

  useEffect(() => {
    setScreenHeight(window.innerHeight-200);
  }, []);
//-------------------------------------------------------------------------------------------

  const goToTop = () => {
    window.scrollTo({
        top:0,
        behavior: 'auto',
        behavior: 'smooth',  
    })
  }
//-------------------------------------------------------------------------------------------

  const set_alert = (msg, status) => {
    setAlert({
      msg: msg,
      status: status.toUpperCase(),
    });
    goToTop();
  };
//-------------------------------------------------------------------------------------------

  return(
    <div className="container mb-5" style={{ minHeight: `${screenHeight}px` }}>

      <div className="mt-4">
          <PageTitle text="פרופיל"/>
      </div>

      {
        alert.msg != null ? (
          <div className="mt-3" dir="ltr">
            <Alert
              severity={alert.status.toLowerCase()} // Set severity based on the alert status ('success', 'error', etc.)
              variant="outlined"
              onClose={() => {
                setAlert({ msg: null, status: 'SUCCESS' }); // Reset alert
              }}
            >
              <AlertTitle>
                <span className="bold-text">
                  {alert.status}
                </span>
              </AlertTitle>
              <span className="bold-text">{alert.msg}</span>
            </Alert>
          </div>
        ) : null
      }

      <div className="profile mt-3">

          <div className="profile-img">
            <ProfileStatus 
              userId={props.id}
              refreshPicture={props.refreshPicture}
              alert={set_alert}
            />
          </div>

          <div className="profile-dtls">         
            <AccountDtls 
              userId={props.id}
              refreshName={props.refreshName}
              alert={set_alert}
            />
          </div>

          <div className="profile-settings">

            {props.auth==='NORMAL'?
              <div className="password">
                <ChangePassword 
                  userId={props.id}
                  alert={set_alert}
                />
              </div>
              :
              null
            }

            <div className="job">
              <Settings 
                userId={props.userId} 
                user={props.user}
                alert={set_alert}
              />
            </div>

          </div>

      </div>

    </div>
  )
};

export default TchProfile;
