import React, { useState } from "react";
import '../Components/search_by_date.css';

const ReportByMonth = (props) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth(); // Get the current month (0-11)

    // Generate an array of years from 2020 up to the previous year if current month is January, else up to the current year
    const years = Array.from({ length: currentMonth === 0 ? currentYear - 2020 : currentYear - 2020 + 1 }, (_, i) => (2020 + i).toString());

    // Months from 01 to 12
    const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

    // Set initial values for month and year selection, defaulting to the previous month
    const [year, setYear] = useState(currentMonth === 0 ? years.length - 2 : years.length - 1);
    const [month, setMonth] = useState(currentMonth === 0 ? 11 : currentMonth - 1);

    const setDateRange = () => {
        const yyyy = parseInt(years[year]);
        const mm = parseInt(month) + 1;
        const dd = new Date(yyyy, mm, 0).getDate();
        const startDate = years[year] + "-" + months[month] + "-01";
        const endDate = years[year] + "-" + months[month] + "-" + dd.toString();
        props.setDateRange(startDate, endDate);
    };

    return (
        <div className="d-flex flex-wrap gap-4 align-items-center">
            <div className="label-100-wrap">
                <label className="form-label label-100" htmlFor="year">שנה</label>
                <div>
                    <select className="form-select" id="year" name="year" style={{ width: "100px" }} value={year} onChange={e => setYear(e.target.value)}>
                        {
                            years.map((y, index) => (
                                <option key={index} value={index}>{y}</option>
                            ))
                        }
                    </select>
                </div>
            </div>

            <div className="label-100-wrap">
                <label className="form-label label-100" htmlFor="month">חודש</label>
                <div>
                    <select className="form-select" id="month" name="month" style={{ width: "100px" }} value={month} onChange={e => setMonth(e.target.value)}>
                        {
                            // Show only months up to the previous month for the current year, otherwise show all months
                            months.slice(0, parseInt(years[year]) === currentYear ? (currentMonth === 0 ? 12 : currentMonth) : 12).map((m, index) => (
                                <option key={index} value={index}>{m}</option>
                            ))
                        }
                    </select>
                </div>
            </div>

            <div>
                <button className="btn btn-primary btn-sm" style={{ borderRadius: "20px", width: "70px" }} onClick={setDateRange}>הצג</button>
            </div>
        </div>
    );
};

export default ReportByMonth;
