import LessonBox from "./lesson_box";
import 'bootstrap/dist/css/bootstrap.rtl.min.css';

const StdLessonBox = (props) => {
    
  return (
    
    <div className="position-relative">                                                                                     
                
      <div>
        <LessonBox
          lessonId={props.lessonId}
          userId={props.userId} 
          bgcolor={props.bgcolor} 
          teacher={props.teacher} 
          tchId={props.tchId}
          mode={props.mode}
          rating={props.rating} 
          ratings={props.ratings} 
          subject={props.subject} 
          date={props.date} 
          start={props.start}  
          lessons_num={props.lessons_num}
          end={props.end} 
          price={props.price} 
          lessonsNum={props.lessonsNum}
          photo={props.photo} 
          user={props.user}
          platform={props.platform}
          recording={props.recording}            
          done={props.done}
          std_done={props.std_done}
          cancelled={props.cancelled}
          refresh={props.refresh}
          status={props.status}
          cal={props.cal}
          index={props.index}
          updateLessonStatus={props.updateLessonStatus}
          updateLessonRating={props.updateLessonRating}
          set_show_overlay={props.set_show_overlay}
          alert={props.alert}
        />
      </div>

    </div>
  )
}

export default StdLessonBox;
