import React, { useState } from "react";
import { storage } from "./firebase";
import { ref,uploadBytes} from "firebase/storage";
import { v4 } from 'uuid';


const UploadFile = (props) => {

    const [selectedFile,setSelectedFile]=useState(null);
   
//-------------------------------------------------------------------------------------------      

    const changeHandler = (event) => {
        const myFile = event.target.files[0];
        const fileDtls = myFile.name.split(".");
        
        // Check if file has at least one dot, indicating there is an extension present
        if(fileDtls.length > 1){
            // The file format is always after the last dot
            const format = fileDtls[fileDtls.length - 1].toLowerCase();
 
            if(format === 'pdf') {
                setSelectedFile(myFile);
            } else {
                alert("סוג הקובץ אינו מתאים!!");
                document.getElementById('file').value = null;
                setSelectedFile(null);
            }
        } else {
            alert("שם קובץ אינו תקין!!");
            document.getElementById('file').value = null;
            setSelectedFile(null);
        }                    
    }
//-------------------------------------------------------------------------------------------      
    
    const pdfFileUpload = () => {
        if( selectedFile!=null ){
            
            props.isLoding(true)
            const file = v4()
            const fileRef=ref(storage,`docs/${file}`)

            uploadBytes(fileRef,selectedFile).then(()=>{                              
                props.addNewLine(file)                               
            }).then(()=>{                                              
                document.getElementById('file').value= null; 
                setSelectedFile(null)
                props.isLoding(false)               
            }).catch((error)=>{
                props.alert(error.message,'ERROR')
                props.isLoding(false)
            });
        }                                      
    }
//-------------------------------------------------------------------------------------------      

    return (
        <div className="d-flex flex-wrap gap-4 align-items-center"> 
                    
            <div className="label-100-wrap" >
                <label class="form-label label-100" for="file">בחר קובץ PDF</label>              
                <div>
                    <input className="form-control" type="file" name="file" id="file" onChange={changeHandler} style={{maxWidth:"270px"}}/>
                </div>
            </div>
            
            <div>
                {(props.count<8 && !props.empty && selectedFile!=null)?            
                    <button class="btn btn-primary btn-sm" style={{borderRadius:"20px",width:"70px"}} onClick={pdfFileUpload}>שלח</button>
                    :
                    <button class="btn btn-primary btn-sm" style={{borderRadius:"20px",width:"70px"}} disabled>שלח</button>
                }
            </div>                                                                              
            
        </div>

    )

}

export default UploadFile;


