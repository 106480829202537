import React, { useState, useEffect, useRef } from "react";
import PageTitle from "../Components/page_title";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import Divider from "@mui/material/Divider";
import ReactLoading from "react-loading";
import axios from "axios";
import { Alert } from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";
import "../Components/alert.css";
import ReportByMonth from "../Components/report_by_month";


const baseUrl = process.env.REACT_APP_BASE_URL;

const TchsTaxReport = (props) => {
  const [reportItems, setReportItems] = useState([]);
  const total = useRef(0);
  const [screenHeight, setScreenHeight] = useState(0);
  const [fire, setFire] = useState(false);
  const [sDate,setSdate]=useState(null);
  const [eDate,setEdate]=useState(null);

  const [alert, setAlert] = useState({
    msg: null,
    status: "SUCCESS",
  });

  useEffect(() => {
    setScreenHeight(window.innerHeight - 200);
  }, []);
//-------------------------------------------------------------------------------------------

  const setDateRange = async (s_date, e_date) => {

    total.current = 0;
    setSdate(s_date)
    setEdate(e_date)

    try {
      setFire(true);
      const response = await axios.get(`${baseUrl}/tchs_income_report.php`, {
        params: {
          sDate: s_date,
          eDate: e_date,
        },
      });

      if (
        response.data.status === "success" &&
        Array.isArray(response.data.data) &&
        response.data.data.length
      ) {
        setReportItems(response.data.data);
      } else if (response.data.status === "info" && response.data.message) {
        set_alert(response.data.message, "INFO");
        setReportItems([]);
      } else if (response.data.status === "error" && response.data.message) {
        throw new Error(response.data.message);
      } else {
        setReportItems([]);
      }
    } catch (error) {
      set_alert(error.message, "ERROR");
      setReportItems([]);
    } finally {
      setFire(false);
    }

  };
//-------------------------------------------------------------------------------------------

  const generatePdfReport = async () => {

    try {
      setFire(true);
      const response = await axios.get(`${baseUrl}/tchs_income_report_pdf.php`, {
        params: {
          sDate: sDate,
          eDate: eDate,
          ReportType:'TAX',
        },
      });

      if ( response.data.status === "success" && response.data.url) {
        window.open(response.data.url, '_blank');
        
      } else if (response.data.status === "error" && response.data.message) {
        throw new Error(response.data.message);
      } 
    } catch (error) {
      set_alert(error.message, "ERROR");
    } finally {
      setFire(false);
    }

  };
//-------------------------------------------------------------------------------------------

  const calculateTotal = (field) => {
    return reportItems.reduce((sum, item) => sum + parseFloat(item[field]), 0).toFixed(2);
  };
//-------------------------------------------------------------------------------------------

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
//-------------------------------------------------------------------------------------------
 
  const set_alert = (msg, status) => {
    setAlert({
      msg: msg,
      status: status,
    });
    goToTop();
  };
//-------------------------------------------------------------------------------------------

  return (
    <div className="container mb-4" style={{ minHeight: `${screenHeight}px` }}>
      <div className="mt-4">
        <PageTitle text="תשלומי מס הכנסה למורים" />
      </div>

      <div className="mt-5 ms-2">
        {/* Component to pick the date range and pass to setDateRange */}
        <ReportByMonth 
          setDateRange={setDateRange}
          alert={set_alert} 
        />
      </div>

      <div className="mt-4 mb-4">
        <Divider />
      </div>

      <div className="d-flex flex-column gap-2 position-relative">
        {fire ? (
          <div
            className="position-absolute start-50 translate-middle-x mt-5"
            style={{ zIndex: "1" }}
          >
            <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70} />
          </div>
        ) : null}

        {alert.msg != null ? (
          <div className="mb-2" dir="ltr">
            <Alert
              severity={alert.status.toLowerCase()} // Set severity based on the alert status ('success', 'error', etc.)
              variant="outlined"
              onClose={() => {
                setAlert({ msg: null, status: "SUCCESS" }); // Reset alert
              }}
            >
              <AlertTitle>
                <span className="bold-text">
                  {alert.status === "SUCCESS" ? "Success" : "Error"}
                </span>
              </AlertTitle>
              <span className="bold-text">{alert.msg}</span>
            </Alert>
          </div>
        ) : null}

        {/* Table for displaying retrieved data */}
        {reportItems.length > 0 && (
          <div className="table-responsive">
            <table className="table table-bordered table-hover mt-4">
              <thead className="table-light">
                <tr>
                  <th>#</th>
                  <th>מורה</th>
                  <th>ת"ז</th>
                  <th>סה"כ פרוטו ₪</th>
                  <th>עמלה ₪</th>
                  <th>שיעור המס %</th>
                  <th>מס ₪</th>
                  <th>נטו לתשלום ₪</th>
                </tr>
              </thead>
              <tbody>
                {reportItems.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.teacher_name}</td>
                    <td>{item.identity}</td>
                    <td>{item.gross_income}</td>
                    <td>{item.platform_commission_amount}</td>
                    <td>{item.tax_rate * 100}</td>
                    <td>{item.tax_amount}</td>
                    <td>{item.net_total_income.toFixed(2)}</td>
                  </tr>
                ))}
                {/* Add a row at the end to display the total */}
                <tr style={{backgroundColor:'lightgray'}}>
                  <td colSpan="2">
                    <button type="button" className="btn btn-primary btn-sm btn-warning" onClick={generatePdfReport} style={{width:'100%'}}>
                      <b>יצא דוח</b>
                    </button>
                  </td>
                  <td></td>
                  <td className="fw-bold">{`${calculateTotal("gross_income")} ₪`}</td>
                  <td className="fw-bold text-success">{`${calculateTotal("platform_commission_amount")} ₪`}</td>
                  <td></td>
                  <td className="fw-bold text-danger">{`${calculateTotal("tax_amount")} ₪`}</td>
                  <td className="fw-bold text-primary">{`${calculateTotal("net_total_income")} ₪`}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default TchsTaxReport;
