import React, { useState,useEffect } from "react";
import { Modal,Form} from 'react-bootstrap'; 
import Button from '@mui/material/Button';
import '../Components/forms.css';
import './popup_modals.css';
import { FaChalkboardTeacher } from 'react-icons/fa';
import * as Yup from 'yup'; 

const loginSchema = Yup.object().shape({ 

    subject: Yup.string()
        .min(5, 'Subject must be at least 5 characters')
        .max(40, 'Subject must be at most 40 characters')
        .required('Subject is required'),
              
});

const UpdateLessonStatus=(props)=>{ 
     
    const [show,setShow]=useState(false);   
    const [subject,setSubject] = useState("");    
    const [errors, setErrors] = useState({});  
        
//-------------------------------------------------------------------------------------------

    useEffect(()=>{

        if(!props.link)
            setShow(true)

    },[])
//------------------------------------------------------------------------------------------- 

    const handleChange = event => {
        setSubject(event.target.value)        
    }
//------------------------------------------------------------------------------------------- 

    const handleError = (error) => {
        if (error.inner) {
            const formattedErrors = error.inner.reduce((acc, { path, message }) => {
                acc[path] = message;
                return acc;
            }, {});
            setErrors(formattedErrors);
        } else {
            alert(error.message,'ERROR');
        }
    };
//------------------------------------------------------------------------------------------- 

    const handleSubmit = async (event) => {

        event.preventDefault();
        
        try {
            await loginSchema.validate({subject}, { abortEarly: false });
            props.getSubject(subject) 
            closeBox()

        } catch (error) {
            handleError(error);
        }   
    }
//------------------------------------------------------------------------------------------- 

    const alert=(msg,status)=>{
        closeBox()
        props.alert(msg,status)
    }
//------------------------------------------------------------------------------------------- 

    const openBox = () => { 
        setShow(true);
    }
//------------------------------------------------------------------------------------------- 

    const closeBox = () => { 
          setShow(false);
          setSubject("")
          if(!props.link)
              props.resetMenu(0);
    }
//------------------------------------------------------------------------------------------- 
    
    return (  
        <div>        
            
            {props.link?
                <button 
                    type="button" 
                    className="btn btn-outline-success btn-custom"  
                    onClick={openBox}
                    disabled={props.disabled}
                >
                    {props.btnText}
                </button>
                :null
            }                         
            
            <Modal 
              show={show}  
              size="md"
              aria-labelledby="contained-modal-title-vcenter"            
             >  

                <Modal.Header style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}>
                    <div className="d-flex gap-2 align-items-center">
                        <div><FaChalkboardTeacher size={30} /></div>
                        <div className="mt-1"><h5>{props.titleTxt}</h5></div>
                    </div>  
                    <button
                        type="button"
                        className="custom-modal-close"
                        aria-label="Close"
                        onClick={closeBox}
                    >
                        &times;  {/* Close "X" icon */}
                    </button>                                            
                </Modal.Header>   
                
                <Form onSubmit={handleSubmit}>
                    <Modal.Body> 
                                                                                           
                        <div className="label-100-wrap mt-3">                                           
                            <label className="form-label label-100" for="subject">נושא השיעור</label>                                                                                                                          
                            <input  
                                type="text" 
                                className={`form-control ${errors.subject? 'invalid' : ''}`} 
                                name="subject" 
                                value={subject} 
                                onChange={handleChange} 
                                placeholder="נושא שיעור"
                            />
                            {errors.subject && <p className="error">{errors.subject}</p>}
                        </div>

                        {props.children}
                                                                      
                    </Modal.Body>  

                    <Modal.Footer>
                        <div className="d-flex gap-3">                         
                            <Button variant="contained" color="error"   size="small" onClick={closeBox}>ביטול</Button>  
                            <Button variant="contained" color="success" size="small" type="submit">{props.submitText}</Button>                         
                        </div>
                    </Modal.Footer> 

                </Form>        
            </Modal> 
        </div>        
    )  
  } 
  
export default UpdateLessonStatus;  
