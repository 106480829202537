
import React, { useState,useEffect } from "react";
import { FcApproval } from "react-icons/fc";
import { MdCancel } from "react-icons/md";
import { FaRegEyeSlash } from "react-icons/fa";
import { GiSandsOfTime } from "react-icons/gi";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import ExpiredCountdownTimer from "./expired_countdown_timer"; 
import '../Components/std_meeting_req_box.scss';
import noIcon from '../Assets/Images/logo192.png'
import axios from 'axios';


const baseUrl = process.env.REACT_APP_BASE_URL;

const twelveHoursInMilliseconds = 12 * 60 * 60 * 1000; // Convert 12 hours to milliseconds 


const StdMeetingReqBox = (props) =>{
  const [checked, setChecked] = useState(false);
  const [status,setStatus]=useState(props.status);
  const [statusDate,setStatusDate]=useState(props.statusDate);
  
  useEffect(() => {

    // Function to fetch and update the status
    const fetchStatus = async () => {

      const currentDate = new Date(); // Get the current date and time
          
      try {
        const response = await axios.get(`${baseUrl}/get_p_lesson_status.php`, {
          params: {
            lessonId: props.lessonId
          }
        });

        if (response.data.status) {
          
          setStatus(response.data.status);                  
          setStatusDate(response.data.sDate); 
          const lessonDateTime = new Date(props.date + ' ' + props.start); // Combine lesson date and time
          const timeDiff = lessonDateTime.getTime() - currentDate.getTime(); // Calculate the time difference in milliseconds
          
          if (timeDiff < twelveHoursInMilliseconds || (status === 'APPROVED' && currentDate.getTime() - new Date(response.data.sDate).getTime() > twelveHoursInMilliseconds)) {
            setStatus("TIMEOUT");
          }
          
        }
  
      } catch (error) {
          props.alert(error.message,'ERROR');
      }
    }

    fetchStatus();

    // Set up the interval to fetch the status every 5 seconds
    const interval = setInterval(fetchStatus, 5000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, [props.lessonId]);

  useEffect(() => {

    // Immediately update the state when props change
    setStatus(props.status);
    setStatusDate(props.statusDate);
    setChecked(props.checked);

  }, [props.status, props.statusDate, props.checked]);
//-------------------------------------------------------------------------------------------  

  const deleteMeetingLine = async () => {

    props.turnFireOn() 

    try {
      const response = await axios.get(`${baseUrl}/delete_meeting_line.php`,{
        params: {
         id:props.lessonId
        }
      })
     
      if(response.data.id > 0){
        if(checked)
          props.updateTotal(props.lessonId,props.price*-1)
        props.removeMeetingLine(props.lessonId)
      }
      else
        props.alert("מחיקת פריט מסל הקניות נכשלה,נסה שנית!!",'ERROR')  
    } 
    catch (error) {

      props.alert(error.message,'ERROR') ;
      
    } finally {
      props.turnFireOff()
    }

  }
//-------------------------------------------------------------------------------------------  

  const handleChange = () => {
    
    setChecked(prev=>!prev)
    
    var price=props.price;
    
    if(checked)
      price=price*-1;

    const lessonDtls={ 
      id:props.lessonId,
      price:props.price,
      amount:props.num,
      teacher:props.teacher,
      subject:props.subject,
    }
  
    props.updateTotal(lessonDtls,price*props.num)
   
  }
//-------------------------------------------------------------------------------------------      

  return (
           
    <div className="std-meeting-req-box" style={{background: props.bgcolor}}>
      
      {status==="APPROVED" || status==="PAID"?
        <Checkbox checked={checked} color="secondary" onChange={handleChange}/>
        :
        <Checkbox color="secondary" disabled /> 
      } 
             
      <div className="subject-icon-container">
        <div className="frame">
          <img 
            src={props.photo != null ? props.photo : noIcon} 
            alt="subject icon" 
            className="subject-icon"  
          /> 
        </div>       
      </div>

      <div className="date-time">
        <p className="date">{props.date}</p>
        <p className="time">{`${props.start} - ${props.end}`}</p>
      </div>

      <div class="lesson-num">{props.num}</div>
      
      <div class="price">{`₪${props.price*props.num}`}</div>
      
      <div className="status-container" style={{width:"110px"}}>                              
        <span>
          { status==='NO_RESPONSE'?
              <FaRegEyeSlash color="red" className="status" />
            :status==="APPROVED" || status==="PAID" ?
              <FcApproval className="status" />              
            :status==="TIMEOUT"?
              <GiSandsOfTime className="status"/>
            :<MdCancel color="red" className="status"/>
          }
        </span>

        {status==="APPROVED" || status==="PAID"?                    
          <span>
            <ExpiredCountdownTimer 
              targetDate={new Date(statusDate).getTime()+twelveHoursInMilliseconds} 
              alert={props.alert}
            />
          </span>
          :
          null
        }
      </div>

      <IconButton 
        aria-label="delete" 
        size="large"                                                
        onClick={deleteMeetingLine}
      >
        <DeleteIcon fontSize="inherit"/>
      </IconButton>
                                                                                            
    </div>

  );
};
export default StdMeetingReqBox;


    
