
import React, { useEffect, useState } from "react";
import StartLesson from "./start_lesson";
import { updateGroupLessonRating,updatePrivateLessonRating } from "./db";
import axios from 'axios';
import '../Components/lesson_box.scss'
import noIcon from '../Assets/Images/logo192.png'
import AddLessonToCalendar from "./add_lesson_to_calendar";
import BasicRating from "./rating";

const baseUrl = process.env.REACT_APP_BASE_URL;

const LessonBox = (props) =>{
  
  const [timer1,setTimer1]=useState(true);
  const [timer2,setTimer2]=useState(true);
  
  useEffect(()=>{
    
    setTimer1(true)
    setTimer2(true)
    
  },[props.refresh])
//-------------------------------------------------------------------------------------------

  const addLessonToCart= async () => {
       
    try {
                    
          const response = await axios.get(`${baseUrl}/add_lesson_to_cart.php`,{
            params: {                        
              lessonId:props.lessonId, 
              stdId:props.userId,
              price:props.price,
              lessonsNum:props.lessonsNum                                           
            }
          })
                  
          if(response.data.status==='success'){
            props.alert(response.data.message,'SUCCESS')
            props.updateLessonStatus(props.index)
          }
          else{
            throw new Error(response.data.message || 'An unknown error occurred');
          }
    } 
    catch (error) {
      props.alert(error.message,'ERROR') ; 
    }
  }
//-------------------------------------------------------------------------------------------

  const updateRating=(value)=>{
    
    if(props.mode==='PRIVATE')
      updatePrivateLessonRating(props.lessonId,props.tchId,value);
    else
      updateGroupLessonRating(props.lessonId,props.userId,props.tchId,value);

    props.updateLessonRating(props.index,value)

  }
//-------------------------------------------------------------------------------------------

  const startOfLesson = () => {

    setTimer1(false)

  }
//-------------------------------------------------------------------------------------------    

  const endOfLesson = () => {

    setTimer2(false)
    if(props.user!=='STD')
      props.endOfLesson() 

  }
//-------------------------------------------------------------------------------------------    
 
  return (
      <div className="lesson-box" style={{background: props.bgcolor}}>
              
        <div className="subject-icon-container">
          <div className="frame">
              <img 
                  src={props.photo != null ? props.photo : noIcon} 
                  alt="subject icon" 
                  className="subject-icon" 
              />
          </div>
        </div>

        <div className="subject-title-container">
          <p className="subject-title">{props.subject}</p>
        </div>

        <div className="rating-container">
          <div className="teacher-rating">
            <p className="teacher-name">{props.teacher}</p>
            <span dir="ltr">
              <BasicRating 
                value={props.rating===null?0:props.rating} 
                ratings={props.ratings} 
                updateRating={updateRating}
                readOnly={ timer2 || !props.std_done || !props.done } 
                refresh={props.refresh}  
                alert={props.alert}                
              />             
            </span>
          </div>
        </div>

        <div className="date-time">
          <p className="date">{props.date}</p>
          <p className="time">{`${props.start} - ${props.end}`}</p>
        </div>

        <div class="price">
          {`₪${props.price}`}
        </div>
              
        <StartLesson 
          user={props.user} 
          mode={props.mode} 
          userId={props.userId}
          lessonId={props.lessonId}
          status={props.status} 
          addLessonToCart={addLessonToCart} 
          date={props.date} 
          start={props.start}
          end={props.end} 
          startOfLesson={startOfLesson}
          timer={timer1}
          recording={props.recording}            
          done={props.done}
          cancelled={props.cancelled}
          endOfLesson={endOfLesson}
          refresh={props.refresh}
          topic={props.subject}
          platform={props.platform}
          alert={props.alert} 
        /> 

        <div className="position-absolute top-0 end-0">  
          {props.children}
        </div>

        <div className="position-absolute" style={{ [props.user !== 'TCH' ? 'top' : 'bottom']: 3,'left': 6 }}>
          <AddLessonToCalendar
            topic={props.subject}
            date={props.date}
            start={props.start} 
            lessons_num={props.lessons_num}
            enable={ props.cal===0 && ((props.user === 'STD' && timer2 && props.status==='PAID' && !props.cancelled) || (props.user !=='STD' && timer2 && !props.cancelled))} 
            lessonId={props.lessonId}
            mode={props.mode}
            user={props.user}
            userId={props.userId}
            set_show_overlay={props.set_show_overlay}
            alert={props.alert} 
          />
        </div> 

      </div>
  );    
};

export default LessonBox;




    



